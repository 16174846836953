import { AxiosProvider } from "src/utils/transport/axios.context";
import React, { FC, useEffect } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "src/store/store";
import { StorageProvider } from "src/context/StorageContext";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BlockUIProvider } from "ns-react-block-ui";
import logo from "./assets/images/logo.png";
import ReactGA from "react-ga";
import { BrowserRouter } from "react-router-dom";
const App: FC = () => {
  ReactGA.initialize("256518575");
  const logoFunc = () => {
    return (
      <>
        <img
          src={logo}
          alt="Logo"
          className="d-inline-block align-top logo loading-img-logo"
        />
      </>
    );
  };

  return (
    <BlockUIProvider loader={logoFunc()}>
      <>
        <BrowserRouter>
          <ReduxProvider store={store}>
            <StorageProvider>
              <AxiosProvider />
            </StorageProvider>
          </ReduxProvider>
          <ToastContainer />
        </BrowserRouter>
      </>
    </BlockUIProvider>
  );
};

export default App;
