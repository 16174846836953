import { API, BASIC_AUTH, CRM_ID } from './constants';
export const baseURL = API.Auth;
export const capURL = API.CAP;
export const v1Customer = API.V1CUSTOMER;
export const v1Points = API.V1POINTS;
export const v2Ledger = API.V2LEDGER;

export const APP_URLS = {
  ForgotPassword: () => `${baseURL}password/forget`,
  PasswordChange: () => `${baseURL}password/change`,
  PasswordValidate: () => `${baseURL}password/validate`,
  OtpValidate: () => `${baseURL}otp/validate`,
  OtpGenerate: () => `${baseURL}otp/generate`,
  tokenGenerate: () => `${baseURL}token/generate`,
  TokenReGenearte: () => `${baseURL}mfa/token/regenerate`,
  GetCustomerByEMail: () => `${capURL}customer/getbyemail`,
  GetCustomerByEMailLoggedIn: () => `${capURL}get/customerbyemail`,
  AddCustomer: () => `${capURL}ecustomer/add`,
  UpdateCustomer: () => `${capURL}update/customer`,
  GetCustomerV1: () => `${v1Customer}get`,
  GetCustomerTransactionV1: () => `${capURL}customer/transactions`,
  GetCustomerRewards: () => `${capURL}get/customer/redemptions`,
  ChangeEmail_Mobile: () => `${capURL}customer/update_identity`,
  ContactUs: () => `${capURL}communications/email`,
  LookUp: () => `${capURL}v2/customers/lookup`,
  CRMSession: () => `${capURL}crm/session`,
  Reservations: () => `${capURL}reservation`,
  LookUpAuthToken: (auth: any) => `${capURL}v1/auth/token?Authorization=Basic ${auth}`,
  GetCustomerLedgerV2: () => `${v2Ledger}getCustomerLedgerInfo`,
  GetHotelsListV2: () => `${capURL}get/all/stores`,
  EmailSubscriptionV2: () => `${capURL}update/customer`,
  GetSubscriptionV2: () => `${capURL}subscriptions`,
  CrmData: (type: any) => `contentsystem/${CRM_ID}/${type}`,
  IsRedeemable: () => `${v1Points}isredeemable`,
  RedeemPoints: () => `${capURL}points/redeem`,
};