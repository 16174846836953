import React, { FC, useEffect } from "react";
import useAuth from "src/context/AuthContext";
import { useStorage } from "src/utils/storage";
import NavigationStack from "./NavigationStack";
const MainNav: FC = () => {
  const storage = useStorage();
  const { user } = useAuth();
  const loadLanguages = async () => {
    // const lang = await storage.get("lang");
    // initLang(lang);
  };

  useEffect(() => {
    loadLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage]);

  return (
    <NavigationStack loggedIn={user && user.isProfileExist}></NavigationStack>
  );
};

export default MainNav;
