import { useBlockUIContext } from "ns-react-block-ui";
import { useLocation } from "react-router-dom";
import { BASIC_SSO_AUTH, CHAIN_ID } from "src/config/constants";
import useAuth from "src/context/AuthContext";
import { errorToast } from "../toast";
import { useApiAxiosRequests } from "./MPHApis";

export function useMPHSSO() {
  const location = useLocation();
  const { setUserProfile } = useAuth();
  const { setBlockUI } = useBlockUIContext();
  const { getCustomerLookUp, getCustomerLookUpAuthToken, getCRMSession } =
    useApiAxiosRequests();

  const getCustomerLookUpApi = async (profile: any) => {
    let params: any = {};
    const store: any = profile;
    if (store && store.email) {
      params = {
        identifierName: "email",
        identifierValue: store.email,
      };
      setBlockUI({ blocking: true, message: "Please Wait Loading" });
      const res: any = await getCustomerLookUp(params);
      if (res && res.data && res.data.id) {
        const token: any = await getCustomerLookUpAuthToken(
          {
            GrantType: "CLIENT_CREDENTIALS",
            UserIPAddress: "127.0.0.1",
            UserType: "Hotel",
            BusinessContext: "WBSVC",
          },
          BASIC_SSO_AUTH,
          store.email
        );
        if (token.data && token.data.access_token) {
          const crmSession: any = await getCRMSession(
            {
              ChainId: CHAIN_ID,
              IDContext: "CRM",
              ProfileID: res.data.id,
              UserType: "Guest",
            },
            { token: token.data.access_token },
            store.email
          );
          if (crmSession.data && crmSession.data.access_token) {
            const path = location.search.split("backurl=");
            if (path && path.length > 1) {
              const redirectUrl =
                path[1] + "&session=" + crmSession.data.access_token;
              window.location.replace(redirectUrl);
              setBlockUI({ blocking: false });
              setTimeout(() => {
                setUserProfile(profile);
              });
              return true;
            }
          } else {
            errorToast(
              crmSession?.data?.error_description ||
                "Unable to redirect to booking engine"
            );
            setBlockUI({ blocking: false });
            return false;
          }
        }
        setUserProfile(profile);
        setBlockUI({ blocking: false });
        return false;
      }
      setUserProfile(profile);
      setBlockUI({ blocking: false });
      return false;
    }
    return false;
  };

  return {
    getCustomerLookUpApi,
  };
}
