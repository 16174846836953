/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Helmet } from 'react-helmet';
import MPHFooterPrimary from "src/components/actionPerformers/MPHFooterPrimary/MPHFooterPrimary";
import MPHFooterSecondary from "src/components/actionPerformers/MPHFooterSecondary/MPHFooterSecondary";
import MPHHeaderMain from "src/components/actionPerformers/MPHHeaderMain/MPHHeaderMain";
import "./MyBenefits.scss";
import MPHMyBenefitsComponent from "src/components/actionPerformers/MPHMyBenefitsComponent/MPHMyBenefitsComponent";

const MyBenefits = () => {

  return (
    <>
      <Helmet>
        <title>Stay Rewarded Member Benefits - My Place Hotels</title>
        <meta name="description" content="Explore exclusive benefits of Stay Rewarded. Enjoy special rates, earn points, and access personalized offers at My Place Hotels." />
      </Helmet>
      <MPHHeaderMain active="/benefits" />
      <div className="margin-top">
        <div className="benefits-container">
          <MPHMyBenefitsComponent/>
        </div>
        <MPHFooterPrimary />
        <MPHFooterSecondary />
      </div>
    </>
  );
};

export default MyBenefits;
