import React from "react";
import MPHButton from "../../actionPerformers/MPHButtons/MPHButton";
import "./../common.scss";

const MPHPrimaryCard = ({
  url,
  header,
  helperText,
  buttonText,
  height,
  buttonType,
  onbuttonClick,
}: any) => {
  return (
    <div className="primary-card">
      <img className={"card-img " + height} src={url} alt="" />
      <section className="card-content">
        {header ? (
          <h1 className={!helperText ? "card-header mb-3" : "card-header"}>
            {header}
          </h1>
        ) : (
          ""
        )}
        {helperText ? (
          <div className="card-helper mb-2 mt-2">
            <p className="mb-0">{helperText}</p>
          </div>
        ) : (
          ""
        )}
        {buttonText ? (
          <MPHButton
            buttonText={buttonText}
            onbuttonClick={onbuttonClick}
            buttonType={buttonType}
          />
        ) : (
          ""
        )}
      </section>
    </div>
  );
};

export default MPHPrimaryCard;
