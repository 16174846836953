/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MPHFooterPrimary from "src/components/actionPerformers/MPHFooterPrimary/MPHFooterPrimary";
import MPHFooterSecondary from "src/components/actionPerformers/MPHFooterSecondary/MPHFooterSecondary";
import MPHHeaderMain from "src/components/actionPerformers/MPHHeaderMain/MPHHeaderMain";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import MPHTablePagination from "src/components/actionPerformers/MPHTablePagination/MPHTablePagination";
import { useStorage } from "src/utils/storage";
import moment from "moment";
import { useBlockUIContext } from "ns-react-block-ui";
import { DatePicker } from "antd";
import "./MyRewards.scss";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const MyRewards = () => {
  const [rewards, setMyRewards]: any = useState([]);
  const [dateFilter, setdateFilter]: any = useState({
    start_date: "",
    end_date: "",
  });
  const { setBlockUI } = useBlockUIContext();
  const { RangePicker } = DatePicker;
  const { getCustomerRewards } = useApiAxiosRequests();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);

  const storage = useStorage();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getRewardsFunc = async (date: any) => {
    setCurrentPage(1);
    let params: any = {};
    const store: any = storage.get("user_profile");
    if (store && JSON.parse(store) && JSON.parse(store).email) {
      params = {
        email: JSON.parse(store).email,
        ...date,
        limit: 20,
      };
      setBlockUI({ blocking: true });
      const res: any = await getCustomerRewards(params);
      setBlockUI({ blocking: false });
      if (
        res &&
        res.data &&
        res.data.response &&
        res.data.response.customer &&
        res.data.response.customer.redemptions &&
        res.data.response.customer.redemptions.points &&
        res.data.response.customer.redemptions.points.point &&
        res.data.response.customer.redemptions.points.point.length
      ) {
        setMyRewards(res.data.response.customer.redemptions.points.point);
      }
    }
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  };

  const labels: any = {
    5000: "$10.00 eGift Card",
    12500: "$25.00 eGift Card",
    25000: "$50.00 eGift Card",
    50000: "$100.00 eGift Card",
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    getRewardsFunc(dateFilter);
    return () => {};
  }, []);

  return (
    <>
      <MPHHeaderMain active="/rewards" />
      <div className="margin-top mt-web">
        <section className="container mobile-device mb-5">
          <section className="d-flex justify-between">
            <div className="label-cards mb-3 d-flex justify-content-between">
              <h2>My Rewards</h2>
            </div>
            <section>
              <RangePicker
                className="range-picker"
                placeholder={["Start Date", "End Date"]}
                disabledDate={disabledDate}
                style={{ height: "48px" }}
                onChange={(text: any) => {
                  const dates = {
                    start_date: text?.[0]?.format("YYYY-MM-DD"),
                    end_date: text?.[1]?.format("YYYY-MM-DD"),
                  };
                  setdateFilter(dates);
                  getRewardsFunc(dates);
                }}
              />
            </section>
          </section>
          <div className="rewards table pl-0">
            <table>
              <thead>
                <tr>
                  <th>Award</th>
                  <th>Points</th>
                  <th>Issued</th>
                  {/* <th>Status</th> */}
                  <th>Voucher</th>
                </tr>
              </thead>
              <tbody>
                {" "}
                {rewards
                  .slice(
                    currentPage === 1 ? 0 : (currentPage - 1) * 10,
                    (currentPage - 1) * 10 + 10
                  )
                  .map((val: any, key: any) => {
                    return (
                      <tr key={key}>
                        <td>{labels[val.points_redeemed] || "--"}</td>
                        <td>{val.points_redeemed}</td>
                        <td>
                          {val.redeemed_time
                            ? moment(val.redeemed_time).format("MM-DD-YYYY")
                            : "--"}
                        </td>
                        {/* <td>{val.status || "Redeemed"}</td> */}
                        <td>{val.transaction_number || "--"}</td>
                      </tr>
                    );
                  })}{" "}
                {!rewards.length ? (
                  <tr>
                    <th>No redemptions found</th>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>

            <MPHTablePagination
              totalItems={rewards.length}
              itemsPerPage={10}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </section>
        <MPHFooterPrimary />
        <MPHFooterSecondary />
      </div>
    </>
  );
};

export default MyRewards;
