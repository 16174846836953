import React from 'react'
import './MPHFooterSecondary.scss'
import { INTERNAL_REDIRECTION_URLS } from "src/config/constants";

const MPHFooterSecondary = () => {
  const navigateToPage = (redirect?: any) => {
    window.open(redirect, "_blank");
  };
  return (
    <>
      <div className="secondary-footer d-flex align-items-center justify-content-center">
        <p className='secondary-footer-content m-0'>© {new Date().getFullYear()} My Place Hotels.  All Rights Reserved.</p>
        <span className='secondary-footer-content ' onClick={() => {
          navigateToPage(INTERNAL_REDIRECTION_URLS.MPH_SECURITY_PRIVACY);
        }}>Security & Privacy</span>
        <span className='secondary-footer-content' onClick={() => {
          navigateToPage(INTERNAL_REDIRECTION_URLS.MPH_ACCESSIBILITY);
        }}>Accessibility</span>
        <span className='secondary-footer-content' onClick={() => {
          navigateToPage(INTERNAL_REDIRECTION_URLS.MPH_TERMS_AND_CONDITIONS);
        }}>Terms & Conditions</span>

      </div>
    </>
  )
}

export default MPHFooterSecondary