/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MPHInput from "src/components/DataProviders/MPHInput";
import modalClose from "../../../assets/images/modalClose.png";
import MPHButton from "../MPHButtons/MPHButton";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import {
  emailChangeSchema,
  emailChangeValidation,
} from "src/utils/MPHFormsSchemaValidations/MPHFormsSchemaValidations";
import { useFormik } from "formik";
import { useStorage } from "src/utils/storage";
import { errorToast, successToast } from "src/utils/toast";
import useAuth from "src/context/AuthContext";
import { useNavigate } from "react-router-dom";

const MPHEmailChange = () => {
  const [initialValues] = useState(emailChangeSchema);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { setUserProfile, clearStorage } = useAuth();
  const navigate = useNavigate();
  const storage = useStorage();
  const { changeEmail } = useApiAxiosRequests();

  const submitFormData = async () => {
    const payload = {
      root: {
        customer: [
          {
            identifier: "email",
            old_value: email.toLowerCase(),
            new_value: values.new_value.toLowerCase(),
          },
        ],
      },
    };
    setIsLoading(true);
    const changeResponse: any = await changeEmail(payload, email.toLowerCase());
    setIsLoading(false);
    if (
      changeResponse.data &&
      changeResponse.data.status &&
      changeResponse.data.status.success !== "true"
    ) {
      errorToast(
        email.toLowerCase() === values.new_value.toLowerCase()
          ? "New email cannot be same as previous email"
          : changeResponse.data?.status?.message ===
            "All requests have failed due to errors"
          ? "Unable to update, email already in use"
          : changeResponse.data?.status?.message
      );
      setIsLoading(false);
      return;
    }
    successToast("Email Updated Successfully, Please login to continue");
    handleChangeSuccess();
  };

  const handleChangeSuccess = async () => {
    const modal = document.getElementById("forEmail");
    const bm = document.getElementsByClassName("modal-backdrop");
    const mp = document.getElementsByClassName("modal-open");
    if (modal && modal.classList.contains("show")) {
      modal.classList.remove("show");
      modal.style.display = "none";
    }
    if (bm && bm[0].classList.contains("show")) {
      bm[0].classList.remove("show");
      bm[0].classList.remove("modal-backdrop");
    }
    if (mp && mp[0].classList.contains("modal-open")) {
      mp[0].classList.remove("modal-open");
    }
    clearStorage();
    setUserProfile(null);
    navigate("/");
  };

  useEffect(() => {
    const store: any = storage.get("user_profile");
    if (store && JSON.parse(store) && JSON.parse(store).email) {
      setEmail(JSON.parse(store).email.toLowerCase());
    }
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: emailChangeValidation,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: submitFormData,
  });

  const { values, handleChange, handleBlur, errors, touched, isValid } = formik;

  return (
    <>
      <div
        className="modal fade"
        id="forEmail"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="forEmailLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content form-modal">
            <div className="row">
              <section className="mb-3">
                <h2 className="form-header">Update Email</h2>
                <img
                  className="close-modal"
                  src={modalClose}
                  alt=""
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </section>
              <div className="col-12 mt-3">
                <label htmlFor="" className="form-label">
                  OLD EMAIL
                </label>
                <MPHInput
                  placeholder=""
                  keyboardType={"email"}
                  value={email}
                  disabled={true}
                  isModal={true}
                />
              </div>
              <div className="col-12 mt-3">
                <label htmlFor="" className="form-label">
                  NEW EMAIL
                </label>
                <MPHInput
                  placeholder=""
                  keyboardType={"email"}
                  value={values.new_value}
                  isModal={true}
                  onBlur={handleBlur("new_value")}
                  onChangeText={(text: any) => {
                    handleChange("new_value")(text);
                  }}
                  isError={touched.new_value! && !!errors.new_value}
                  error={errors.new_value}
                />
              </div>
              <div className="col-12 mt-3">
                <label htmlFor="" className="form-label">
                  Password
                </label>
                <MPHInput
                  placeholder=""
                  keyboardType={"password"}
                  value={values.password}
                  isModal={true}
                  onBlur={handleBlur("password")}
                  onChangeText={(text: any) => {
                    handleChange("password")(text);
                  }}
                  isError={touched.password! && !!errors.password}
                  error={errors.password}
                />
              </div>
              <div className="col-12 mt-4">
                <MPHButton
                  disabled={!isValid || isLoading}
                  isLoading={isLoading}
                  buttonText="Submit"
                  onbuttonClick={() => submitFormData()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MPHEmailChange;
