import React, { useEffect, useState } from "react";
import MPHInput from "src/components/DataProviders/MPHInput";
import MPHButton from "../MPHButtons/MPHButton";
import "./MPHLogin.scss";
import modalClose from "../../../assets/images/modalClose.png";
import MPHOtp from "../MPHOtp/MPHOtp";
import {
  loginSchema,
  loginValidation,
  signUpSchema,
  signUpValidation,
} from "src/utils/MPHFormsSchemaValidations/MPHFormsSchemaValidations";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import { useFormik } from "formik";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { errorToast, successToast } from "src/utils/toast";
import { AUTH_ROUTES, BRAND_NAME, DEVICE_ID } from "src/config/constants";
import useAuth from "src/context/AuthContext";
import MPHForgotPassword from "../MPHForgotPassword/MPHForgotPassword";
import { useMPHSSO } from "src/utils/ApiService/MPHSSO";

const errorCodes ={
  "1515": "User doesn't exist. Please try again.",
  "1527": "User doesn't exist. Please try again.",
  "1528": "Password entered is invalid, Please try again.",
  "400": "Profile not found"
}

const MPHLogin = ({ screenType }: any) => {
  const [isOtp, setIsOtp] = useState(false);
  const [isUpdateUser, setIsUpdateUser]: any = useState(false);
  const [isCreatePassword, setIsCreatePassword]: any = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [registeredForPassword, setRegisteredForPassword] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [initialValues] = useState(loginSchema);
  const [initialValuesSetPassword] = useState(signUpSchema);
  const [token, setTokenState] = useState("");
  const location = useLocation();
  const { setTokens, setUserProfile } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { getCustomerLookUpApi } = useMPHSSO();

  const {
    passwordValidate,
    getCustomerByEmail,
    tokenGenerate,
    otpGenerate,
    otpValidate,
    getCustomerByEmailLoggedIn,
  } = useApiAxiosRequests();

  const tokenGenerateFunc = async () => {
    const payload = {
      identifierType: "EMAIL",
      identifierValue: values.identifierValue.toLowerCase(),
      password: values.password,
      confirmPassword: values.password,
      deviceId: DEVICE_ID,
      brand: BRAND_NAME,
    };
    const res: any = await tokenGenerate(payload);
    if (res.data && res.data.status && res.data.status.success) {
      setTokenState(res.data.auth);
      setRegisteredForPassword(res.data.user.userRegisteredForPassword);
      setSessionId(res.data.user.sessionId);
    }
    return {
      status: res.data && res.data.status && res.data.status.success,
      data: res.data,
    };
  };

  const updateCustomerFunc = async () => {
    generateOtpFunc({ sessionId: sessionId }, true);
  };

  const handleSaveNext = async () => {
    let payload: any = values;
    payload.identifierValue = payload.identifierValue.toLowerCase()
    let userDetails: any;
    setIsLoading(true);
    userDetails = await tokenGenerateFunc();
    if (!userDetails.status) {
      errorToast(
        (userDetails.data &&
          userDetails.data.status &&
          userDetails.data.status.message) ||
          "Please check username password. Your entry did not match our records"
      );
      setIsLoading(false);
      return;
    }
    const data: any = await getCustomerByEMailFunc(
      values.identifierValue.toLowerCase(),
      userDetails.data.auth.token
    );
    if (data.data && data.data.customers !== "LOYALTY") {
      errorToast("User does not exist, Please validate email");
      setIsLoading(false);
      return;
    }
    if (!userDetails.data.user.userRegisteredForPassword) {
      if (
        data &&
        data.data &&
        data.data.status &&
        data.data.status.success === "true"
      ) {
        setIsUpdateUser(true);
        setIsLoading(false);
        return;
      } else if (!data?.data?.customers) {
        errorToast("User does not exist, Please validate email");
        setIsLoading(false);
        return;
      }
      generateOtpFunc(userDetails.data.user);
      return;
    } else if (
      data &&
      data.data &&
      data.data.status &&
      data.data.status.success === "false"
    ) {
      errorToast("User does not exist, Please validate email");
      setIsLoading(false);
      return;
    }
    payload["sessionId"] = userDetails.data.user.sessionId;
    const res: any = await passwordValidate(payload);
    if (res && res.data && res.data.status && !res.data.status.success) {
      setIsLoading(false);
      errorToast(res.data.status.message);
      return;
    }
    if (res && res.data && res.data.status && res.data.status.success) {
      if (res.data.auth && res.data.auth.token) {
        handleLoginSuccess(res.data.auth, res.data.status.message);
      }
    } else {
      errorToast((res && res.data && res.data.message) || "Error logging In");
    }
    setIsLoading(false);
  };

  const handleLoginSuccess = async (auth: any, message?: any) => {
    const user: any = await getCustomerByEmailLoggedIn(
      { email: values.identifierValue.toLowerCase() },
      auth.token
    );
    if (
      !user?.data?.status?.success ||
      user?.data?.status?.success === "false"
    ) {
      errorToast("User does not exist, Please validate email");
      return;
    }
    const customer: any =
      user &&
      user.data &&
      user.data.customers &&
      user.data.customers.customer &&
      user.data.customers.customer.length &&
      user.data.customers.customer[0];
    setTokens(auth);
    setIsOtp(false);
    setIsCreatePassword(false);
    setIsForgotPassword(false);
    const profile = {
      method: "auth",
      email: values.identifierValue.toLowerCase(),
      previousStep: "",
      token: auth.token,
      role: "User",
      isProfileExist: true,
      links: AUTH_ROUTES,
      profileName: (customer && customer.firstname) || "",
      loyalty_points: (customer && customer.loyalty_points) || 0,
    };
    // if (isSsoNavigation()) {
    //   ssoNavigation(profile);
    // } else {
      setUserProfile(profile);
      setIsLoading(false);
      if (message) {
        successToast(message);
      }
      navigate("/status");
    // }
  };

  const getCustomerByEMailFunc = async (email: any, token: any) => {
    const data = await getCustomerByEmail({ email: email }, token);
    return data;
  };

  const generateOtpFunc = async (data: any, update?: any) => {
    let sessionIdForDirect: any;
    setIsLoading(true);
    if (isCreatePassword) {
      setFieldValue(
        "identifierValue",
        setPasswordFormik.values.identifierValue.toLowerCase()
      );
      const payload = {
        identifierType: "EMAIL",
        identifierValue: setPasswordFormik.values.identifierValue.toLowerCase(),
        password: setPasswordFormik.values.password,
        confirmPassword: setPasswordFormik.values.password,
        deviceId: DEVICE_ID,
        brand: BRAND_NAME,
      };
      const res: any = await tokenGenerate(payload);
      if (res.data && res.data.status && res.data.status.success) {
        setTokenState(res.data.auth);
        setRegisteredForPassword(res.data.user.userRegisteredForPassword);
        setSessionId(res.data.user.sessionId);
        sessionIdForDirect = res.data.user.sessionId;
        const data: any = await getCustomerByEMailFunc(
          setPasswordFormik.values.identifierValue.toLowerCase(),
          res.data.auth.token
        );
        if (data.data && data.data.customers !== "LOYALTY") {
          errorToast("User does not exist, Please validate email");
          setIsLoading(false);
          return;
        }
      } else {
        errorToast("User does not exist, Please validate email");
        setIsLoading(false);
        return;
      }
    }
    let payload: any = {
      identifierType: values.identifierType,
      identifierValue: values.identifierValue.toLowerCase(),
      deviceId: values.deviceId,
      brand: values.brand,
      sessionId: sessionIdForDirect || data.sessionId,
    };
    if (update) {
      payload["password"] = setPasswordFormik.values.password;
      payload["confirmPassword"] = setPasswordFormik.values.password;
      payload["identifierValue"] = setPasswordFormik.values.identifierValue.toLowerCase();
    }
    const res: any = await otpGenerate(payload);
    setIsLoading(false);
    if (res.data && res.data.status && res.data.status.success) {
      successToast(res.data.status.message);
      setIsOtp(true);
    } else {
      errorToast(
        (res && res.data && res.data.status && res.data.status.message) ||
          "Error Generating in otp, Please try again"
      );
    }
  };

  const otpValidateFunc = async (otp: any) => {
    const payload: any = {
      identifierType: values.identifierType,
      identifierValue: values.identifierValue.toLowerCase(),
      deviceId: values.deviceId,
      brand: values.brand,
      sessionId: sessionId,
      otp: otp,
    };
    if (registeredForPassword || isCreatePassword) {
      payload["password"] = setPasswordFormik.values.password;
      payload["confirmPassword"] = setPasswordFormik.values.password;
      payload["identifierValue"] = setPasswordFormik.values.identifierValue.toLowerCase();
    }
    setIsLoading(true);
    const res: any = await otpValidate(payload);
    if (res.data && res.data.status && res.data.status.success) {
      handleLoginSuccess(res.data.auth, res.data.status.message);
    } else {
      errorToast(
        (res && res.data && res.data.status && res.data.status.message) ||
          "Error Validating otp, Please try again"
      );
    }
    setIsLoading(false);
  };

  const isSsoNavigation = () => {
    if (location.search.includes("backurl")) {
      return true;
    } else {
      return false;
    }
  };

  const ssoNavigation = (profile: any) => {
    getCustomerLookUpFunc(profile);
  };

  const getCustomerLookUpFunc = async (profile: any) => {
    setIsLoading(true);
    const lookup: any = await getCustomerLookUpApi(profile);
    setIsLoading(false);
    console.log(lookup);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginValidation,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleSaveNext,
  });

  const setPasswordObj = useFormik({
    initialValues: initialValuesSetPassword,
    validationSchema: signUpValidation,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleSaveNext,
  });
  const setPasswordFormik = setPasswordObj;

  const {
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    isValid,
  } = formik;

  //changes made to show error based on code from url
  useEffect(() => {
    const code = searchParams.get("code");
     if(code == "1515" || code == "1527") {
       errorToast(errorCodes[code]);
     } else if(code == "1528") {
       errorToast(errorCodes[code]);
     } else if(code == "400") {
       errorToast(errorCodes[code]);
     } else {
       return;
     }
   }, []);

  useEffect(() => {
    if (screenType === "create-password") {
      setIsCreatePassword(true);
      setIsUpdateUser(true);
      setIsOtp(false);
      setIsForgotPassword(false);
    }
    if (screenType === "forgot-password") {
      setIsCreatePassword(false);
      setIsUpdateUser(false);
      setIsOtp(false);
      setIsForgotPassword(true);
    }
  }, [screenType]);

  return (
    <>
      <div className="d-flex justify-center">
        <section className="login-modal">
          {isForgotPassword ? (
            <>
              <MPHForgotPassword
                isModal={false}
                onModalClose={() => {
                  setIsForgotPassword(false);
                  setIsCreatePassword(false);
                  setIsUpdateUser(false);
                  setIsOtp(false);
                }}
              />
            </>
          ) : (
            <>
              {!isOtp ? (
                isUpdateUser ? (
                  <>
                    <div className="modal-content login-form-modal">
                      <div className="row">
                        <section className="mb-3">
                          <h2 className="form-header">
                            {isCreatePassword
                              ? "Create your password"
                              : "Confirm Password"}
                          </h2>
                          <img
                            className="close-modal"
                            src={modalClose}
                            alt=""
                            onClick={() => {
                              setIsForgotPassword(false);
                              setIsCreatePassword(false);
                              setIsUpdateUser(false);
                              setIsOtp(false);
                            }}
                          />
                        </section>
                        <div className="col-12 mt-3">
                          <MPHInput
                            keyboardType="email"
                            placeholder="Email"
                            value={setPasswordFormik.values.identifierValue}
                            isModal={true}
                            onBlur={setPasswordFormik.handleBlur(
                              "identifierValue"
                            )}
                            onChangeText={(text: any) => {
                              setPasswordFormik.handleChange("identifierValue")(
                                text
                              );
                            }}
                            isError={
                              setPasswordFormik.touched.identifierValue! &&
                              !!setPasswordFormik.errors.identifierValue
                            }
                            error={setPasswordFormik.errors.identifierValue}
                          />
                        </div>
                        <div className="col-12 mt-4">
                          <MPHInput
                            keyboardType="password"
                            placeholder="Password"
                            value={setPasswordFormik.values.password}
                            onBlur={setPasswordFormik.handleBlur("password")}
                            onChangeText={(text: any) => {
                              setPasswordFormik.handleChange("password")(text);
                            }}
                            isError={touched.password! && !!errors.password}
                            error={errors.password}
                            isModal={true}
                          />
                        </div>
                        <div className="col-12 mt-4">
                          <MPHInput
                            keyboardType="password"
                            placeholder="Confirm Password"
                            value={setPasswordFormik.values.confirmPassword}
                            onBlur={setPasswordFormik.handleBlur(
                              "confirmPassword"
                            )}
                            onChangeText={(text: any) => {
                              setPasswordFormik.handleChange("confirmPassword")(
                                text
                              );
                            }}
                            isError={
                              setPasswordFormik.touched.confirmPassword! &&
                              !!setPasswordFormik.errors.confirmPassword
                            }
                            error={setPasswordFormik.errors.confirmPassword}
                            isModal={true}
                          />
                        </div>
                        <div className="col-12 mt-4">
                          <MPHButton
                            disabled={!setPasswordFormik.isValid || isLoading}
                            isLoading={isLoading}
                            buttonText="Submit"
                            onbuttonClick={() => updateCustomerFunc()}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="modal-content login-form-modal">
                      <div className="row">
                        <section className="mb-3">
                          <h2 className="form-header">Login</h2>
                        </section>
                        <div className="col-12 mt-3">
                          <MPHInput
                            keyboardType="email"
                            placeholder="Email"
                            value={values.identifierValue}
                            isModal={true}
                            onBlur={handleBlur("identifierValue")}
                            onChangeText={(text: any) => {
                              handleChange("identifierValue")(text);
                            }}
                            isError={
                              touched.identifierValue! &&
                              !!errors.identifierValue
                            }
                            error={errors.identifierValue}
                          />
                        </div>
                        <div className="col-12 mt-4">
                          <MPHInput
                            keyboardType="password"
                            placeholder="Password"
                            value={values.password}
                            onBlur={handleBlur("password")}
                            onChangeText={(text: any) => {
                              handleChange("password")(text);
                            }}
                            isError={touched.password! && !!errors.password}
                            error={errors.password}
                            isModal={true}
                          />
                        </div>
                        <div className="col-12">
                          <h3
                            className="forgot-password mt-1"
                            onClick={() => {
                              setIsForgotPassword(true);
                              setIsCreatePassword(false);
                            }}
                          >
                            Forgot your password?
                          </h3>
                        </div>
                        <div className="col-12">
                          <h3 className="first-time-sign-in mt-1">
                            First time signing in?
                            <span
                              onClick={() => {
                                setIsUpdateUser(true);
                                setIsCreatePassword(true);
                              }}
                              className="forgot-password ml-1"
                            >
                              Create your password
                            </span>
                          </h3>
                        </div>
                        <div className="col-12 mt-4">
                          <MPHButton
                            disabled={!isValid || isLoading}
                            isLoading={isLoading}
                            buttonText="Login"
                            onbuttonClick={() => handleSaveNext()}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : (
                <MPHOtp
                  text={values.identifierValue}
                  isLoading={isLoading}
                  isModal={false}
                  darkTheme={true}
                  resend={() => generateOtpFunc({ sessionId: sessionId })}
                  onClose={() => {
                    setIsOtp(false);
                    setIsUpdateUser(false);
                    setIsForgotPassword(false);
                    setIsCreatePassword(false);
                  }}
                  onOtp={(otp: any) => otpValidateFunc(otp)}
                />
              )}
            </>
          )}
        </section>
      </div>
    </>
  );
};

export default MPHLogin;
