import { BRAND_NAME, DEVICE_ID } from "src/config/constants";
import * as Yup from "yup";

export const mandateSchema = {
  deviceId: DEVICE_ID,
  brand: BRAND_NAME,
};

export const loginSchema = {
  identifierType: "EMAIL",
  identifierValue: "",
  password: "",
  ...mandateSchema,
};

export const forGotPasswordSchema = {
  identifierType: "EMAIL",
  identifierValue: "",
  password: "",
  confirmPassword: "",
  ...mandateSchema,
};

export const redeemPointsSchema = {
  points: "",
};

export const signUpSchema = {
  identifierType: "EMAIL",
  identifierValue: "",
  password: "",
  confirmPassword: "",
  ...mandateSchema,
};

export const passwordChangeSchema = {
  identifierType: "EMAIL",
  identifierValue: "",
  newPassword: "",
  password: "",
  confirmPassword: "",
  ...mandateSchema,
};

export const emailChangeSchema = {
  identifierType: "EMAIL",
  old_value: "",
  new_value: "",
  password: "",
  confirmPassword: "",
  ...mandateSchema,
};

export const mobileChangeSchema = {
  identifier: "mobile",
  old_value: "",
  new_value: "",
  ...mandateSchema,
};

export const signUpProfileUpdateSchema = {
  mobile: "",
  firstname: "",
  lastname: "",
  subscriptions: {
    subscription: [
      { priority: "BULK", scope: "all", is_subscribed: "1", channel: "email" },
    ],
  },
  custom_fields: {
    field: [
      { name: "middle_name", value: "" },
      { name: "type_of_phone", value: "Mobile" },
    ],
  },
  extended_fields: {
    field: [{ name: "member_type", value: "Loyalty_web" }],
  },
};

export const contactSchema = {
  firstname: "",
  mobile: "",
  from: "",
  to: "",
  membership: "",
  subject: "",
  checkIn: "",
  checkOut: "",
  confirmationNumber: "",
  destination: "",
  body: "",
  ...mandateSchema,
};

export const corporateSchema = {
  firstName: "",
  lastName: "",
  city: "",
  number: "",
  email: "",
  contactemail: "",
  contactePhone: "",
  ...mandateSchema,
};

export const profileOneSchema = {
  mobile: "",
  firstname: "",
  lastname: "",
  email: "",
  // subscriptions: {
  //   subscription: [
  //     { priority: "BULK", scope: "all", is_subscribed: "1", channel: "email" },
  //   ],
  // },
  extended_fields: {
    gender: "",
    country_of_residence: "",
    city: "",
    state: "",
    dob_date: "",
    company: "",
    zip: "",
  },
  custom_fields: {
    middle_name: "",
    title: "",
    business_title: "",
    address_one: "",
    address_two: "",
    suffix: "",
    type_of_phone: "",
    preferred_contact: "",
    Ice_Maker_Required: "",
    room_type: "",
    floor_preference: "",
    near_elevator: "",
    travel_with_pets: "",
    type_of_pet: "",
    reason_for_travel: "",
    Kids_Activities: "",
    Cooking: "",
    Live_Music_and_Conce: "",
    Local_Culture_Excurs: "",
    Mountain_Winter_Spor: "",
    other: "",
    Outdoor_Fitness_Spor: "",
    Nature_and_Wildlife: "",
    Professional_and_Col: "",
    Shopping: "",
    Theatre_Fine_Arts: "",
    Wine_and_Beer_Enthus: "",
    other_values: "",
    dob_date: "",
    dob_month: "",
  },
  ...mandateSchema,
};

export const loginValidation = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(
      8,
      `The password must be at least 8 characters and contain at
                  least one uppercase character`
    )
    .matches(
      /^(?=.*[A-Z]).{8,}$/,
      `The password must be at least 8 characters and contain at
                  least one uppercase character`
    ),
  identifierValue: Yup.string()
    .email("Please enter valid email")
    .required("Email is Required*"),
});

export const findHotelValidation = Yup.object().shape({
  destination: Yup.string().required("Destination are Required*"),
  arrive: Yup.string().required("Dates are Required*"),
  depart: Yup.string().required("Dates is Required*"),
});

export const redeemPointsValidation = Yup.object().shape({
  points: Yup.string().required("Points are Required*"),
});

export const emailChangeValidation = Yup.object().shape({
  new_value: Yup.string()
    .email("Please enter valid email")
    .required("Email is Required*"),
  password: Yup.string()
    .required("Password is required")
    .min(
      8,
      `The password must be at least 8 characters and contain at
                  least one uppercase character`
    )
    .matches(
      /^(?=.*[A-Z]).{8,}$/,
      `The password must be at least 8 characters and contain at
                  least one uppercase character`
    ),
});

export const mobileChangeValidation = Yup.object().shape({
  new_value: Yup.string()
    .min(10, "Must be exactly 10 characters")
    .max(10, "Must be exactly 10 characters")
    .required("Number is Required*"),
});

export const contactValidation = Yup.object().shape({
  mobile: Yup.string()
    .min(10, "Must be exactly 10 characters")
    .max(10, "Must be exactly 10 characters")
    .required("Number is Required*"),
  body: Yup.string().required("Message is Required*"),
  subject: Yup.string().required("Subject is Required*"),
  destination: Yup.string().when("subject", {
    is: "MSP" || "RI" || "FP",
    then: (v) => v.required("Name of Property is required"),
    otherwise: (v) => v.optional(),
  }),
});

export const signUpValidation = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(
      8,
      `The password must be at least 8 characters and contain at
                  least one uppercase character`
    )
    .matches(
      /^(?=.*[A-Z]).{8,}$/,
      `The password must be at least 8 characters and contain at
                  least one uppercase character`
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is Required"),
  identifierValue: Yup.string()
    .email("Please enter valid email")
    .required("Email is Required*"),
});

export const passwordChangeValidation = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(
      8,
      `The password must be at least 8 characters and contain at
                  least one uppercase character`
    )
    .matches(
      /^(?=.*[A-Z]).{8,}$/,
      `The password must be at least 8 characters and contain at
                  least one uppercase character`
    ),
  newPassword: Yup.string()
    .required("Password is required")
    .min(
      8,
      `The password must be at least 8 characters and contain at
                  least one uppercase character`
    )
    .matches(
      /^(?=.*[A-Z]).{8,}$/,
      `The password must be at least 8 characters and contain at
                  least one uppercase character`
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm Password is Required"),
});

export const forgotPasswordValidation = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(
      8,
      `The password must be at least 8 characters and contain at
                  least one uppercase character`
    )
    .matches(
      /^(?=.*[A-Z]).{8,}$/,
      `The password must be at least 8 characters and contain at
                  least one uppercase character`
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is Required"),
  identifierValue: Yup.string()
    .email("Please enter valid email")
    .required("Email is Required*"),
});

export const corporateValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name is Required*"),
  lastName: Yup.string().required("Last Name is Required*"),
  // city: Yup.string().required("City is Required*"),
  number: Yup.string().required("Number is Required*"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email is Required*"),
});

export const profileOneValidation = Yup.object().shape({
  firstname: Yup.string().required("First Name is Required*"),
  // lastname: Yup.string().required("First Name is Required*"),
  mobile: Yup.string()
    .min(10, "Must be exactly 10 characters")
    .max(10, "Must be exactly 10 characters")
    .required("Number is Required*"),
  extended_fields: Yup.object().shape({
    country_of_residence: Yup.string().required("Country is Required*"),
    state: Yup.string().required("State is Required*"),
    zip: Yup.string().required("Zipcode is Required*"),
    city: Yup.string().required("City is Required*"),
  }),
  custom_fields: Yup.object().shape({
    address_one: Yup.string().required("Address is Required*"),
  }),
});

export const signUpUpdateValidation = Yup.object().shape({
  firstname: Yup.string().required("First Name is Required*"),
  lastname: Yup.string().required("Last Name is Required*"),
  mobile: Yup.string()
    .min(10, "Must be exactly 10 characters")
    .max(10, "Must be exactly 10 characters")
    .required("Number is Required*"),
});
