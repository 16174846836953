/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import MPHProfileFormOne from "./MPHProfileFormOne";
import MPHProfileFormThree from "./MPHProfileFormThree";
import MPHProfileFormTwo from "./MPHProfileFormTwo";
import "./MPHProfileFormsCommon.scss";
import MPHStepper from "src/components/DataProviders/MPHStepper";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import { errorToast, successToast } from "src/utils/toast";
import { useNavigate } from "react-router-dom";
import { useStorage } from "src/utils/storage";
import { useBlockUIContext } from "ns-react-block-ui";
import { getEmail } from "src/utils/session";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const MPHProfileForms = () => {
  const [userDetails, setUserDetails]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { setBlockUI } = useBlockUIContext();
  const location = useLocation();

  const { updateCustomer, getCustomerByEmailLoggedIn, getSubscription } =
    useApiAxiosRequests();
  const navigate = useNavigate();
  const [formStep, setFormStep] = useState(0);
  const storage = useStorage();

  const onSave = async (values: any, navigateUser?: any) => {
    const data = JSON.parse(JSON.stringify(values));

    data["extended_fields"] = {
      field: Object.keys(data.extended_fields).map((val) => {
        return {
          name: val,
          value: data.extended_fields[val],
        };
      }),
    };
    data["custom_fields"] = {
      field: Object.keys(data.custom_fields).map((val) => {
        return {
          name: val,
          value: data.custom_fields[val],
        };
      }),
    };
    data["email"] = getEmail(storage);
    const payload = Object.assign(userDetails, data);
    setIsLoading(true);
    const res: any = await updateCustomer(
      { root: { customer: [payload] } },
      "",
      getEmail(storage)
    );
    setIsLoading(false);
    if (res.data && res.data.status && res.data.status.success === "true") {
      if (navigateUser) {
        successToast("Profile Successfully Updated");
        navigate("/status");
        window.scrollTo(0, 0);
      } else {
        setTimeout(() => {
          setFormStep(formStep + 1);
          scrollToElement();
        });
      }
    } else {
      errorToast(
        (res && res.data && res.data.status && res.data.status.message) ||
          "Error updating profile, Please try again"
      );
    }
  };

  const nextStep = (value: any) => {
    const profileDetails = Object.assign(userDetails, value);
    setUserDetails(profileDetails);
    onSave(profileDetails, formStep === 2 ? true : false);
  };

  const setStepFromStepper = (step: number) => {
    setFormStep(step);
  };

  const prevStep = () => {
    setFormStep(formStep - 1);
  };

  const elementToScrollTo = useRef<null | HTMLDivElement>(null);
  const scrollToElement = () => {
    if (elementToScrollTo.current) {
      elementToScrollTo.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getloggedInUserFunc = async () => {
    const store: any = storage.get("user_profile");
    if (store && JSON.parse(store).email) {
      let payload: any = {
        email: JSON.parse(store).email,
      };
      setBlockUI({ blocking: true });
      const res: any = await getCustomerByEmailLoggedIn(payload);
      setBlockUI({ blocking: false });
      if (
        res &&
        res.data &&
        res.data.status &&
        res.data.status.success === "true"
      ) {
        let user =
          res.data.customers &&
          res.data.customers.customer &&
          res.data.customers.customer.length &&
          res.data.customers.customer[0];
        if (user) {
          setUserDetails({
            mobile: user.mobile,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            extended_fields: user.extended_fields,
            custom_fields: user.custom_fields,
          });
        }
      } else {
        setUserDetails({});
      }
    } else {
    }
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    getloggedInUserFunc();
  }, []);

  return (
    <>
      <div className="my-profile-form-container" ref={elementToScrollTo}>
        <div className="my-profile-header d-flex align-items-center flex-wrap">
          <h4 className="my-profile-heading">My Profile</h4>
          <MPHStepper
            key={formStep}
            activeIndex={formStep}
            setFormStep={setStepFromStepper}
          />
        </div>
        {formStep === 0 && (
          <MPHProfileFormOne
            nextStep={nextStep}
            userDetails={userDetails}
            isLoading={isLoading}
          />
        )}
        {formStep === 1 && (
          <MPHProfileFormTwo
            nextStep={nextStep}
            prevStep={prevStep}
            isLoading={isLoading}
            userDetails={userDetails}
          />
        )}
        {formStep === 2 && (
          <MPHProfileFormThree
            prevStep={prevStep}
            handleSave={nextStep}
            isLoading={isLoading}
            userDetails={userDetails}
          />
        )}{" "}
      </div>
    </>
  );
};

export default MPHProfileForms;
