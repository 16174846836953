/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./MPHProfileFormsCommon.scss";
import MPHButton from "../MPHButtons/MPHButton";
import MPHDropdown from "src/components/DataProviders/MPHDropdown";
import { useFormik } from "formik";
import {
  profileOneSchema,
  profileOneValidation,
} from "src/utils/MPHFormsSchemaValidations/MPHFormsSchemaValidations";

const MPHProfileFormTwo = ({
  nextStep,
  userDetails,
  prevStep,
  isLoading,
}: any) => {
  const [initialValues] = useState(profileOneSchema);

  const submitFormData = () => {
    nextStep(values);
  };

  useEffect(() => {
    if (userDetails) {
      setFieldValue("mobile", userDetails.mobile);
      setFieldValue("firstname", userDetails.firstname);
      setFieldValue("lastname", userDetails.lastname);
      setFieldValue("email", userDetails.email);
      if (
        userDetails.extended_fields &&
        userDetails.extended_fields.field &&
        userDetails.extended_fields.field.length
      ) {
        userDetails.extended_fields.field.forEach((res: any) => {
          if (res.value) {
            setFieldValue(`extended_fields[${res.name}]`, res.value);
          }
        });
      }
      if (
        userDetails.custom_fields &&
        userDetails.custom_fields.field &&
        userDetails.custom_fields.field.length
      ) {
        userDetails.custom_fields.field.forEach((res: any) => {
          if (res.value) {
            setFieldValue(`custom_fields[${res.name}]`, res.value);
          }
        });
      }
    }
  }, [userDetails]);

  const formik = useFormik({
    initialValues,
    validationSchema: profileOneValidation,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: submitFormData,
  });
  const { values, handleChange, setFieldValue, isValid } = formik;

  return (
    <>
      <section className="my-profile-form">
        <div className="my-profile-form-contents">
          <div className="form-part-one">
            <MPHDropdown
              data={[
                "Yes, when possible, I would like to have ice availabile upon my arrival",
                "No, I prefer to make my own ice",
                "No Preference",
              ]}
              key={1}
              labelField=""
              valueField=""
              placeholder="Please Select"
              normalLabel={true}
              label="When you arrive at the hotel, would you like to have the ice maker in your in-room freezer turned on?"
              value={values.custom_fields.Ice_Maker_Required}
              onChangeText={(text: any) => {
                handleChange("custom_fields.Ice_Maker_Required")(text);
              }}
            />
            <MPHDropdown
              data={["1 Bed", "2 Beds", "Accessible", "No Preference"]}
              key={2}
              labelField=""
              valueField=""
              placeholder="Please Select"
              normalLabel={true}
              label="What type of room do you prefer?"
              value={values.custom_fields.room_type}
              onChangeText={(text: any) => {
                handleChange("custom_fields.room_type")(text);
              }}
            />
            <MPHDropdown
              data={["Main Floor", "Top Floor", "No Preference"]}
              labelField=""
              key={3}
              valueField=""
              placeholder="Please Select"
              normalLabel={true}
              label="What floor of the hotel would you like to stay on?"
              value={values.custom_fields.floor_preference}
              onChangeText={(text: any) => {
                handleChange("custom_fields.floor_preference")(text);
              }}
            />
            <MPHDropdown
              data={["Yes", "No", "No Preference"]}
              labelField=""
              valueField=""
              key={4}
              placeholder="Please Select"
              normalLabel={true}
              label="Do you want to be near the elevator?"
              value={values.custom_fields.near_elevator}
              onChangeText={(text: any) => {
                handleChange("custom_fields.near_elevator")(text);
              }}
            />
          </div>
          <div className="form-part-two">
            <MPHDropdown
              data={["Yes", "No"]}
              labelField=""
              valueField=""
              key={5}
              placeholder="Please Select"
              normalLabel={true}
              label="Do you travel with pets?"
              value={values.custom_fields.travel_with_pets}
              onChangeText={(text: any) => {
                handleChange("custom_fields.travel_with_pets")(text);
              }}
            />
            {values.custom_fields.travel_with_pets === "Yes" && (
              <MPHDropdown
                data={["Cat(s)", "Dog(s)", "Both"]}
                labelField=""
                key={6}
                valueField=""
                placeholder="Please Select"
                normalLabel={true}
                label="What kind of pet do you have?"
                value={values.custom_fields.type_of_pet}
                onChangeText={(text: any) => {
                  handleChange("custom_fields.type_of_pet")(text);
                }}
              />
            )}
            <MPHDropdown
              data={[
                "Business",
                "Leisure",
                "Bleisure (combination of Business and Leisure)",
              ]}
              labelField=""
              key={7}
              valueField=""
              placeholder="Please Select"
              normalLabel={true}
              label="What is your reason for traveling?"
              value={values.custom_fields.reason_for_travel}
              onChangeText={(text: any) => {
                handleChange("custom_fields.reason_for_travel")(text);
              }}
            />
          </div>
        </div>
        <div className="profile-form-button-container">
          <MPHButton
            buttonText="Previous"
            buttonType="secondary-button"
            backward={true}
            key={8}
            onbuttonClick={() => prevStep()}
          />
          <MPHButton
            buttonText="Submit"
            buttonType="secondary-button"
            type="submit"
            isLoading={isLoading}
            key={9}
            onbuttonClick={() => submitFormData()}
          />
        </div>
      </section>
    </>
  );
};

export default MPHProfileFormTwo;
