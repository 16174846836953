/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import MPHFooterPrimary from "src/components/actionPerformers/MPHFooterPrimary/MPHFooterPrimary";
import MPHFooterSecondary from "src/components/actionPerformers/MPHFooterSecondary/MPHFooterSecondary";
import MPHHeaderMain from "src/components/actionPerformers/MPHHeaderMain/MPHHeaderMain";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import MPHTablePagination from "src/components/actionPerformers/MPHTablePagination/MPHTablePagination";
import "./MyStays.scss";
import { useStorage } from "src/utils/storage";
import MPHBanner from "src/components/DataHolders/MPHBanners/MPHBanner";
import banner from "../../assets/banners/banner.jpg";
import moment from "moment";
import { useBlockUIContext } from "ns-react-block-ui";
import { DatePicker } from "antd";
import { BASIC_AUTH, CHAIN_ID } from "src/config/constants";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const MyStays = () => {
  const [upComingStays, setUpComingStays]: any = useState([]);
  const [pastStays, setPastStays]: any = useState([]);
  const { setBlockUI } = useBlockUIContext();
  const { RangePicker } = DatePicker;
  const location = useLocation();

  const {
    getCustomerTransaction,
    getCustomerLookUp,
    getCustomerLookUpAuthToken,
    getCustomerByEmailLoggedIn,
    getCustomerResevations,
  } = useApiAxiosRequests();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageUpcoming, setCurrentPageUpcoming] = useState(1);
  const [dateFilter, setdateFilter]: any = useState({
    start_date: "",
    end_date: "",
  });
  const storage = useStorage();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleUpcomingPageChange = (page: number) => {
    setCurrentPageUpcoming(page);
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  };

  const getCustomerLookUpFunc = async () => {
    let params: any = {};
    const store: any = storage.get("user_profile");
    if (store && JSON.parse(store) && JSON.parse(store).email) {
      params = {
        identifierName: "email",
        identifierValue: JSON.parse(store).email,
      };
      setBlockUI({ blocking: true });
      const res: any = await getCustomerLookUp(params);
      const user: any = await getCustomerByEmailLoggedIn({
        email: JSON.parse(store).email,
      });
      setBlockUI({ blocking: false });
      if (res && res.data && res.data.id) {
        const token: any = await getCustomerLookUpAuthToken(
          {
            GrantType: "CLIENT_CREDENTIALS",
            UserIPAddress: "127.0.0.1",
            UserType: "Hotel",
            BusinessContext: "WBSVC",
          },
          BASIC_AUTH,
          JSON.parse(store).email
        );
        if (token.data && token.data.access_token) {
          const reservation: any = await getCustomerResevations(
            {
              token: token.data.access_token,
              chain: CHAIN_ID,
              guestEmail: JSON.parse(store).email,
              view: "Lookup",
              departure: "Future",
            },
            JSON.parse(store).email
          );
          if (reservation.data && reservation.data.Reservations) {
            setUpComingStays(reservation.data.Reservations);
          }
        }
      }
    }
  };

  const getPastStaysFunc = async (date: any) => {
    let params: any = {};
    setCurrentPage(1);
    const store: any = storage.get("user_profile");
    if (store && JSON.parse(store) && JSON.parse(store).email) {
      params = {
        email: JSON.parse(store).email,
        limit: 10000,
        start_date: date && date.start_date,
        end_date: date && date.end_date,
      };
      setBlockUI({ blocking: true });
      const res: any = await getCustomerTransaction(params);
      setBlockUI({ blocking: false });
      if (
        res &&
        res.data &&
        res.data.customer &&
        res.data.customer.transactions &&
        res.data.customer.transactions.transaction &&
        res.data.customer.transactions.transaction.length
      ) {
        const resultDict: any = {};

        res.data.customer.transactions.transaction.forEach(
          (val: any, i: any) => {
            const currentId =
              (val.custom_fields &&
                val.custom_fields.field &&
                val.custom_fields.field.length &&
                val.custom_fields.field.find(
                  (val: any) => val.name === "crsnumber"
                ) &&
                val.custom_fields.field.find(
                  (val: any) => val.name === "crsnumber"
                ).value) ||
              val.number;
            const currentDate =
              val.extended_fields &&
              val.extended_fields.field &&
              val.extended_fields.field.length &&
              val.extended_fields.field.find(
                (val: any) => val.name === "checkout_date"
              ) &&
              moment(
                val.extended_fields.field.find(
                  (val: any) => val.name === "checkout_date"
                ).value
              ).format("MM-DD-YYYY");
            const checkin =
              val.extended_fields &&
              val.extended_fields.field &&
              val.extended_fields.field.length &&
              val.extended_fields.field.find(
                (val: any) => val.name === "checkin_date"
              ) &&
              moment(
                val.extended_fields.field.find(
                  (val: any) => val.name === "checkin_date"
                ).value
              ).format("MM-DD-YYYY");

            const currentPoints = Number(
              (val && val.points && val.points.issued) || 0
            );
            if (currentId) {
              if (
                !resultDict[currentId] ||
                currentDate > resultDict[currentId].checkout
              ) {
                resultDict[currentId] = {
                  crsnumber: currentId,
                  checkout: currentDate,
                  checkin: checkin,
                  totalPoints: currentPoints,
                  ...val,
                };
              } else {
                resultDict[currentId].totalPoints += currentPoints;
              }
              if (checkin < resultDict[currentId].checkin) {
                resultDict[currentId].checkin = checkin;
              }
            } else {
              resultDict["NA" + i] = {
                crsnumber: currentId,
                checkout: currentDate,
                checkin: checkin,
                totalPoints: currentPoints,
                ...val,
              };
            }
          }
        );
        console.log(resultDict);

        const resultList = Object.values(resultDict);
        setPastStays(resultList);
      }
    }
  };

  const getDateDiff = (start: any, end: any) => {
    if (start && end) {
      var a = moment(start);
      var b = moment(end);
      return b.diff(a, "days"); // 1
    } else {
      return "--";
    }
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    getPastStaysFunc(dateFilter);
    getCustomerLookUpFunc();
    return () => {};
  }, []);

  return (
    <>
      <Helmet>
        <title>Stay Rewarded - View Member Stays</title>
        <meta name="description" content="Review your past stays and upcoming reservations with Stay Rewarded. Track your stays and earnings as a valued My Place Hotels member." />
      </Helmet>
      <MPHHeaderMain active="/stays" />
      <div className="margin-top mt-web">
        <MPHBanner
          url={banner}
          header={"Where will you go next?"}
          helperText={""}
        />
        <section className="container mobile-device">
          <section className="d-flex justify-between">
            <div className="label-cards mb-3">
              <h2>Upcoming Stays</h2>
            </div>
          </section>
          <div className="stays table pl-0">
            <table>
              <thead>
                <tr>
                  <th>Property Name</th>
                  <th>Confirmation Number</th>
                  <th>Check In</th>
                  <th>Check Out</th>
                  <th>Status</th>
                  <th>Nights</th>
                </tr>
              </thead>
              <tbody>
                {" "}
                {upComingStays
                  .slice(
                    currentPageUpcoming === 1
                      ? 0
                      : (currentPageUpcoming - 1) * 10,
                    (currentPageUpcoming - 1) * 10 + 10
                  )
                  .map((val: any, key: any) => {
                    return (
                      <tr key={key}>
                        <td>{val?.Hotel?.Name}</td>
                        <td>{val?.CRSConfirmationNumber || "--"}</td>
                        <td>
                          {val?.RoomStay?.StartDate
                            ? moment(val?.RoomStay?.StartDate).format(
                                "MM-DD-YYYY"
                              )
                            : ""}
                        </td>
                        <td>
                          {val?.RoomStay?.EndDate
                            ? moment(val?.RoomStay?.EndDate).format(
                                "MM-DD-YYYY"
                              )
                            : ""}
                        </td>
                        <td>
                          {val.Status || '--'}
                        </td>
                        <td>
                          {getDateDiff(
                            val?.RoomStay?.StartDate,
                            val?.RoomStay?.EndDate
                          )}
                        </td>
                      </tr>
                    );
                  })}{" "}
                {!upComingStays.length ? (
                  <tr>
                    <th>No transactions found</th>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
            <MPHTablePagination
              totalItems={upComingStays.length}
              itemsPerPage={10}
              currentPage={currentPageUpcoming}
              onPageChange={handleUpcomingPageChange}
            />
          </div>
        </section>
        <section className="container mobile-device mb-5">
          <section className="d-flex justify-between">
            <div className="label-cards mb-3 d-flex justify-content-between">
              <h2>Past Stays</h2>
            </div>
            <section>
              <RangePicker
                className="range-picker"
                disabledDate={disabledDate}
                placeholder={["Start Date", "End Date"]}
                style={{ height: "48px" }}
                onChange={(text: any) => {
                  const dates = {
                    start_date: text?.[0]?.format("YYYY-MM-DD"),
                    end_date: text?.[1]?.format("YYYY-MM-DD"),
                  };
                  setdateFilter(dates);
                  getPastStaysFunc(dates);
                }}
              />
            </section>
          </section>
          <div className="stays table pl-0">
            <table>
              <thead>
                <tr>
                  <th>Property Name</th>
                  <th>Confirmation Number</th>
                  <th>Check In</th>
                  <th>Check Out</th>
                  <th>Nights</th>
                  <th>Points Earned</th>
                </tr>
              </thead>
              <tbody>
                {" "}
                {pastStays
                  .slice(
                    currentPage === 1 ? 0 : (currentPage - 1) * 10,
                    (currentPage - 1) * 10 + 10
                  )
                  .map((val: any, key: any) => {
                    return (
                      <tr key={key}>
                        <td>{val.store}</td>
                        <td>{val?.crsnumber || "--"}</td>
                        <td>{val.checkin || "--"}</td>
                        <td>{val?.checkout || "--"}</td>
                        <td>{getDateDiff(val.checkin, val.checkout)}</td>
                        <td>{val?.totalPoints || 0}</td>
                      </tr>
                    );
                  })}{" "}
                {!pastStays.length ? (
                  <tr>
                    <th>No transactions found</th>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>

            <MPHTablePagination
              totalItems={pastStays.length}
              itemsPerPage={10}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </section>
        <MPHFooterPrimary />
        <MPHFooterSecondary />
      </div>
    </>
  );
};

export default MyStays;
