/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import right from "../../../assets/images/right.svg";
import MPHButton from "../MPHButtons/MPHButton";
import "./MPHModalDetails.scss";

const MPHModalDetails = ({ isRedeemed, onClosed }: any) => {
  const onSuccess = () => {
    const modal = document.getElementById("ModalDetails");
    const bm = document.getElementsByClassName("modal-backdrop");
    const mp = document.getElementsByClassName("modal-open");
    if (modal && modal.classList.contains("show")) {
      modal.classList.remove("show");
      modal.style.display = "none";
    }
    if (bm && bm.length && bm[0].classList.contains("show")) {
      bm[0].classList.remove("show");
      bm[0].classList.remove("modal-backdrop");
    }
    if (mp && mp.length && mp[0].classList.contains("modal-open")) {
      mp[0].classList.remove("modal-open");
    }
    if (onClosed) {
      onClosed();
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="ModalDetails"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="ModalDetailsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content form-modal">
            <div className="row">
              <section className="mb-3">
                <img
                  src={right}
                  alt=""
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </section>
              <section className="mb-3 mt-3">
                <h3 className="redemption-success">
                  Your redemption is successful. Please check your inbox to
                  select the eGift card of your choice from our catalog.
                </h3>
              </section>
              <div className="col-12 mt-4">
                <MPHButton
                  buttonText="Complete"
                  onbuttonClick={() => onSuccess()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MPHModalDetails;
