import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function errorToast(text1: string) {
  toast.warn(
    text1,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      progressStyle: { background: '#FFE11B' },
      style:{zIndex: 1, background: 'red'},
      theme: "colored",
    });
}

function successToast(text1: string) {
  toast.success(
    text1,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
}

export { errorToast, successToast };
