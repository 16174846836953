import React, { useRef } from "react";
import "./Common.scss";
type TextInputProps = {
  value?: string;
  placeholder?: string;
  onChangeText?: any;
  icon?: React.ReactNode;
  onBlur?: (e: any) => void;
  disabled?: boolean;
  maxLength?: number;
  isError?: any;
  label?: any;
  error?: any;
  rows?: any;
};

export default function MPHTextarea({
  placeholder,
  value,
  onBlur,
  onChangeText,
  isError,
  error,
  label,
  disabled = false,
  maxLength,
  rows = 5,
}: TextInputProps) {
  const ref: any = useRef(null);
  return (
    <>
      <section className="mb-3">
        {label && <label className="form-label">{label}</label>}
        <section>
          <textarea
            className="form-textarea"
            maxLength={maxLength}
            placeholder={placeholder}
            rows={rows}
            value={value}
            onBlur={onBlur}
            onChange={(txt: any) => onChangeText(txt)}
            disabled={disabled}
            ref={ref}
          />
          {isError && <p className="error-code text-decoration-none">{error}</p>}
        </section>
      </section>
    </>
  );
}
