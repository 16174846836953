/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./MPHProfileFormsCommon.scss";
import MPHInput from "src/components/DataProviders/MPHInput";
import MPHButton from "../MPHButtons/MPHButton";

import MPHDropdown from "src/components/DataProviders/MPHDropdown";

import MPHRadiobutton from "src/components/DataProviders/MPHRadiobutton";
import { useFormik } from "formik";
import {
  profileOneSchema,
  profileOneValidation,
} from "src/utils/MPHFormsSchemaValidations/MPHFormsSchemaValidations";
import MPHChangePassword from "../MPHChangePassword/MPHChangePassword";
import MPHEmailChange from "../MPHEmailChange/MPHEmailChange";
// import { DatePicker } from "antd";
// import moment from "moment";
import { COUNTRIES, PROVINCES } from "src/config/constants";
import { errorToast } from "src/utils/toast";
import MPHMobileChange from "../MPHMobileChange/MPHMobileChange";

const MPHProfileFormOne = ({ userDetails, nextStep, isLoading }: any) => {
  const countries: any = COUNTRIES;
  const provinces: any = PROVINCES;

  const [initialValues] = useState(profileOneSchema);
  const [isNumber, setIsNumber] = useState(true);
  const submitFormData = () => {
    if (isValid) {
      nextStep(values);
    } else {
      Object.keys(initialValues).forEach((key: any) => {
        setFieldTouched(key);
      });
      Object.keys(initialValues.custom_fields).forEach((key: any) => {
        setFieldTouched(`custom_fields.${key}`);
      });
      Object.keys(initialValues.extended_fields).forEach((key: any) => {
        setFieldTouched(`extended_fields.${key}`);
      });
    }
  };
  const getdayCounts = () => {
    if (
      [1, 3, 5, 7, 8, 10, 12].includes(Number(values.custom_fields.dob_month))
    ) {
      return Array.from({ length: 31 }, (_, index) => index + 1);
    }
    if ([2].includes(Number(values.custom_fields.dob_month))) {
      return Array.from({ length: 29 }, (_, index) => index + 1);
    }
    return Array.from({ length: 30 }, (_, index) => index + 1);
  };

  useEffect(() => {
    if (userDetails) {
      if(!userDetails.mobile) {
        setIsNumber(false);
      } else {
        setIsNumber(true);
      }
      setFieldValue("mobile", userDetails.mobile);
      setFieldValue("firstname", userDetails.firstname);
      setFieldValue("lastname", userDetails.lastname);
      setFieldValue("email", userDetails.email);
      if (
        userDetails.extended_fields &&
        userDetails.extended_fields.field &&
        userDetails.extended_fields.field.length
      ) {
        userDetails.extended_fields.field.forEach((res: any) => {
          if (res.value) {
            setFieldValue(`extended_fields[${res.name}]`, res.value);
          }
        });
      }
      if (
        userDetails.custom_fields &&
        userDetails.custom_fields.field &&
        userDetails.custom_fields.field.length
      ) {
        userDetails.custom_fields.field.forEach((res: any) => {
          if (res.value) {
            setFieldValue(`custom_fields[${res.name}]`, res.value);
          }
        });
      }
    }
  }, [userDetails]);

  const openMessage = () => {
    errorToast("Please contact member services to update your name");
  };

  const formik = useFormik({
    initialValues,
    validationSchema: profileOneValidation,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: submitFormData,
  });

  const {
    values,
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    isValid,
  } = formik;

  return (
    <>
      <section className="my-profile-form">
        <div className="my-profile-form-contents">
          <div className="form-part-one">
            <MPHDropdown
              data={["Mr.", "Miss", "Mrs.", "Ms.", "Dr."]}
              labelField=""
              valueField=""
              placeholder="Select"
              optiondisabled={false}
              label="TITLE (OPTIONAL)"
              value={values.custom_fields.title}
              onChangeText={(text: any) => {
                handleChange("custom_fields.title")(text);
              }}
            />
            <div className="split-input" onClick={() => openMessage()}>
              <MPHInput
                keyboardType="text"
                label="First Name"
                placeholder="Enter First Name"
                value={values.firstname}
                onBlur={handleBlur("firstname")}
                disabled={true}
                // onChangeText={(text: any) => {
                //   handleChange("firstname")(text);
                // }}
                // isError={touched.firstname! && !!errors.firstname}
                // error={errors.firstname}
              />
              <MPHInput
                keyboardType="text"
                placeholder="Enter Middle Name"
                label="MIDDLE INITIAL (OPTIONAL)"
                value={values.custom_fields.middle_name}
                disabled={true}
                // onBlur={handleBlur("custom_fields.middle_name")}
                // onChangeText={(text: any) => {
                //   handleChange("custom_fields.middle_name")(text);
                // }}
              />
            </div>
            <MPHInput
              keyboardType="text"
              placeholder="Enter Last Name"
              label="LAST NAME"
              value={values.lastname}
              disabled={true}
              // onBlur={handleBlur("lastname")}
              // onChangeText={(text: any) => {
              //   handleChange("lastname")(text);
              // }}
              // isError={touched.lastname! && !!errors.lastname}
              // error={errors.lastname}
            />
            <MPHDropdown
              data={["Jr", "Sr", "I", "II", "III"]}
              labelField=""
              valueField=""
              optiondisabled={false}
              placeholder="Select"
              label="SUFFIX (OPTIONAL)"
              value={values.custom_fields.suffix}
              onChangeText={(text: any) => {
                handleChange("custom_fields.suffix")(text);
              }}
            />
            <MPHDropdown
              data={["Male", "Female"]}
              labelField=""
              valueField=""
              placeholder="Select"
              optiondisabled={false}
              label="GENDER (OPTIONAL)"
              value={values.extended_fields.gender}
              onChangeText={(text: any) => {
                handleChange("extended_fields.gender")(text);
              }}
            />
            <MPHInput
              keyboardType="text"
              placeholder="Enter Business"
              label="BUSINESS TITLE (OPTIONAL)"
              value={values.custom_fields.business_title}
              onBlur={handleBlur("custom_fields.business_title")}
              onChangeText={(text: any) => {
                handleChange("custom_fields.business_title")(text);
              }}
            />
            <MPHInput
              keyboardType="text"
              placeholder="Enter Company Name"
              label="COMPANY NAME (OPTIONAL)"
              value={values.extended_fields.company}
              onBlur={handleBlur("extended_fields.company")}
              onChangeText={(text: any) => {
                handleChange("extended_fields.company")(text);
              }}
            />
            <MPHInput
              keyboardType="number"
              placeholder="Enter Phone Number"
              label="PHONE"
              value={values.mobile}
              disabled={isNumber}
              onBlur={handleBlur("mobile")}
              onChangeText={(text: any) => {
                if(!isNumber) {
                  handleChange("mobile")(text);
                }
              }}
              isError={touched.mobile! && !!errors.mobile}
              error={errors.mobile}
            />
            {isNumber ? (
              <div className="row mb-2">
                <div
                  data-bs-toggle="modal"
                  className="col-lg-6 col-xl-6 col-md-12 col-sm-12 mb-2"
                  data-bs-target="#forMobile"
                >
                  <MPHButton
                    buttonText="Update Number"
                    buttonType="secondary-button"
                    type="submit"
                    onbuttonClick={() => {}}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            <>
              <div className="radio-group">
                <label className="radio-header">TYPE OF PHONE</label>
                <div className="radio-options">
                  <MPHRadiobutton
                    label="Mobile"
                    valueField={"Mobile"}
                    inputFields={{
                      name: "phone",
                    }}
                    value={values.custom_fields.type_of_phone}
                    onBlur={handleBlur("custom_fields.type_of_phone")}
                    onChangeText={(text: any) => {
                      handleChange("custom_fields.type_of_phone")(text);
                    }}
                  />
                  <MPHRadiobutton
                    label="Business"
                    valueField={"Business"}
                    inputFields={{
                      name: "phone",
                    }}
                    value={values.custom_fields.type_of_phone}
                    onBlur={handleBlur("custom_fields.type_of_phone")}
                    onChangeText={(text: any) => {
                      handleChange("custom_fields.type_of_phone")(text);
                    }}
                  />
                  <MPHRadiobutton
                    label="Home"
                    valueField={"Home"}
                    inputFields={{
                      name: "phone",
                    }}
                    value={values.custom_fields.type_of_phone}
                    onBlur={handleBlur("custom_fields.type_of_phone")}
                    onChangeText={(text: any) => {
                      handleChange("custom_fields.type_of_phone")(text);
                    }}
                  />
                </div>
              </div>
            </>
          </div>
          <div className="form-part-two">
            <MPHInput
              keyboardType="text"
              placeholder="Enter Email"
              label="Email"
              disabled={true}
              value={values.email}
              onBlur={handleBlur("email")}
              onChangeText={(text: any) => {
                handleChange("email")(text);
              }}
              isError={touched.email! && !!errors.email}
              error={errors.email}
            />
            <div className="row mb-2">
              <div
                data-bs-toggle="modal"
                className="col-lg-6 col-xl-6 col-md-12 col-sm-12 mb-2"
                data-bs-target="#forEmail"
              >
                <MPHButton
                  buttonText="Update Email"
                  buttonType="secondary-button"
                  type="submit"
                  onbuttonClick={() => {}}
                />
              </div>
              <div
                data-bs-toggle="modal"
                className="col-lg-6 col-xl-6 col-md-12 col-sm-12 mb-2"
                data-bs-target="#forGotPassword"
              >
                <MPHButton
                  buttonText="Update Password"
                  buttonType="secondary-button"
                  type="submit"
                  onbuttonClick={() => {}}
                />
              </div>
            </div>
            <div className="date-picker">
              <label className="form-label">Birthday (OPTIONAL)</label>
              <div className="row">
                <div className="col-6">
                  <MPHDropdown
                    data={[
                      { name: "January", value: 1 },
                      { name: "February", value: 2 },
                      { name: "March", value: 3 },
                      { name: "April", value: 4 },
                      { name: "May", value: 5 },
                      { name: "June", value: 6 },
                      { name: "July", value: 7 },
                      { name: "August", value: 8 },
                      { name: "September", value: 9 },
                      { name: "October", value: 10 },
                      { name: "November", value: 11 },
                      { name: "December", value: 12 },
                    ]}
                    labelField="name"
                    valueField="value"
                    placeholder="Month"
                    label=""
                    value={values.custom_fields.dob_month}
                    onChangeText={(text: any) => {
                      handleChange("custom_fields.dob_month")(text);
                    }}
                  />
                </div>
                <div className="col-6">
                  <MPHDropdown
                    data={getdayCounts() as any}
                    labelField=""
                    valueField=""
                    placeholder="Date"
                    label=""
                    value={values.custom_fields.dob_date}
                    onChangeText={(text: any) => {
                      handleChange("custom_fields.dob_date")(text);
                    }}
                  />
                </div>
              </div>
              {/* 
              <DatePicker
                mode="date"
                picker="date"
                placeholder="Select Birthday"
                disabledDate={disabledDate}
                value={
                  values.extended_fields.dob_date
                    ? dayjs(values.extended_fields.dob_date)
                    : ("" as any)
                }
                onChange={(text) => {
                  const date: any = text?.toDate();
                  setFieldValue("extended_fields.dob_date", date);
                }}
                className={"form-input mb-3"}
                format="MM-DD"
              /> */}
            </div>

            <MPHInput
              keyboardType="text"
              placeholder="Enter Address Line 1"
              label="ADDRESS1"
              value={values.custom_fields.address_one}
              onBlur={handleBlur("custom_fields.address_one")}
              onChangeText={(text: any) => {
                handleChange("custom_fields.address_one")(text);
              }}
              isError={
                touched.custom_fields?.address_one! &&
                !!errors.custom_fields?.address_one
              }
              error={errors.custom_fields?.address_one}
            />

            <MPHInput
              keyboardType="text"
              placeholder="Enter Address Line 2"
              label="ADDRESS2"
              value={values.custom_fields.address_two}
              onBlur={handleBlur("custom_fields.address_two")}
              onChangeText={(text: any) => {
                handleChange("custom_fields.address_two")(text);
              }}
            />

            <MPHInput
              keyboardType="text"
              placeholder="Enter City"
              label="CITY"
              value={values.extended_fields.city}
              onBlur={handleBlur("extended_fields.city")}
              onChangeText={(text: any) => {
                handleChange("extended_fields.city")(text);
              }}
              isError={
                touched.extended_fields?.city! && !!errors.extended_fields?.city
              }
              error={errors.extended_fields?.city}
            />
            <div className="split-input">
              <MPHDropdown
                data={countries}
                labelField="name"
                valueField="name"
                placeholder="Select"
                label="COUNTRY"
                value={values.extended_fields.country_of_residence}
                onChangeText={(text: any) => {
                  handleChange("extended_fields.country_of_residence")(text);
                  setFieldValue("extended_fields.state", "");
                }}
                isError={
                  touched.extended_fields?.country_of_residence! &&
                  !!errors.extended_fields?.country_of_residence
                }
                error={errors.extended_fields?.country_of_residence}
              />
              <MPHInput
                keyboardType="number"
                placeholder="Enter Zipcode"
                label="ZIPCODE"
                value={values.extended_fields.zip}
                onBlur={handleBlur("extended_fields.zip")}
                onChangeText={(text: any) => {
                  handleChange("extended_fields.zip")(text);
                }}
                isError={
                  touched.extended_fields?.zip! && !!errors.extended_fields?.zip
                }
                error={errors.extended_fields?.zip}
              />
            </div>
            <MPHDropdown
              data={provinces[values.extended_fields.country_of_residence]}
              labelField=""
              valueField="name"
              placeholder="Select"
              label="STATE"
              value={values.extended_fields.state}
              onChangeText={(text: any) => {
                handleChange("extended_fields.state")(text);
              }}
              isError={
                touched.extended_fields?.state! &&
                !!errors.extended_fields?.state
              }
              error={errors.extended_fields?.state}
            />

            {/* <div className="radio-group">
              <label className="radio-header">
                PREFERRED METHOD OF CONTACT
              </label>
              <div className="radio-options">
                <MPHRadiobutton
                  label="Email"
                  valueField={"Email"}
                  inputFields={{
                    name: "contactMethod",
                  }}
                  value={values.custom_fields.preferred_contact}
                  onBlur={handleBlur("custom_fields.preferred_contact")}
                  onChangeText={(text: any) => {
                    handleChange("custom_fields.preferred_contact")(text);
                  }}
                />
                <MPHRadiobutton
                  label="Phone"
                  valueField={"Phone"}
                  inputFields={{
                    name: "contactMethod",
                  }}
                  value={values.custom_fields.preferred_contact}
                  onBlur={handleBlur("custom_fields.preferred_contact")}
                  onChangeText={(text: any) => {
                    handleChange("custom_fields.preferred_contact")(text);
                  }}
                />
              </div>
            </div> */}
          </div>
        </div>
        <div className="pt-4">
          <MPHButton
            buttonText="Submit"
            isLoading={isLoading}
            buttonType="secondary-button"
            type="submit"
            onbuttonClick={() => submitFormData()}
          />
        </div>
      </section>
      <MPHChangePassword />
      <MPHEmailChange />
      <MPHMobileChange
        mobile={values.mobile}
        changed={(number: any) => {
          if (number) {
            setFieldValue("mobile", number);
          }
        }}
      />
    </>
  );
};

export default MPHProfileFormOne;
