import React from "react";
import { createContext } from "react";

type StorageContextType = {
  get: (key: string) => Promise<string | undefined>;
  set: (key: string, value: string) => Promise<void>;
  clear: () => Promise<void>;
};

export const StorageContext = createContext<StorageContextType>(
  {} as StorageContextType
);

export function StorageProvider(props: any) {
  const get = (key: string): any => {
    return localStorage.getItem(key);
  };

  const set = (key: string, value: string): any => {
    localStorage.setItem(key, value);
  };

  const clear = (): any => {
    localStorage.clear();
  };
  return (
    <StorageContext.Provider value={{ clear, get, set }}>
      {props.children}
    </StorageContext.Provider>
  );
}
