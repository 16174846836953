/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./MPHProfileFormsCommon.scss";
import MPHInput from "src/components/DataProviders/MPHInput";
import MPHButton from "../MPHButtons/MPHButton";
import MPHCheckbox from "src/components/DataProviders/MPHCheckBox";

import { useFormik } from "formik";
import {
  profileOneSchema,
  profileOneValidation,
} from "src/utils/MPHFormsSchemaValidations/MPHFormsSchemaValidations";

const MPHProfileFormThree = ({
  userDetails,
  handleSave,
  prevStep,
  isLoading,
}: any) => {
  const [initialValues]: any = useState(profileOneSchema);
  const submitFormData = () => {
    handleSave(values);
  };

  useEffect(() => {
    if (userDetails) {
      setFieldValue("mobile", userDetails.mobile);
      setFieldValue("firstname", userDetails.firstname);
      setFieldValue("lastname", userDetails.lastname);
      setFieldValue("email", userDetails.email);
      if (
        userDetails.extended_fields &&
        userDetails.extended_fields.field &&
        userDetails.extended_fields.field.length
      ) {
        userDetails.extended_fields.field.forEach((res: any) => {
          if (res.value) {
            if (res.value) {
              setFieldValue(`extended_fields[${res.name}]`, res.value);
            }
          }
        });
      }
      if (
        userDetails.custom_fields &&
        userDetails.custom_fields.field &&
        userDetails.custom_fields.field.length
      ) {
        userDetails.custom_fields.field.forEach((res: any) => {
          if (res.value) {
            setFieldValue(`custom_fields[${res.name}]`, res.value);
          }
        });
      }
    }
  }, [userDetails]);

  const formik = useFormik({
    initialValues,
    validationSchema: profileOneValidation,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: submitFormData,
  });
  const { values, handleChange, setFieldValue } = formik;

  const checkboxLeft = [
    { label: "Cooking", value: "Cooking", index: 16 },
    { label: "Kids Activities", value: "Kids_Activities", index: 17 },
    { label: "Live Music/Concerts", value: "Live_Music_and_Conce", index: 18 },
    {
      label: "Local Culture/Excursions",
      value: "Local_Culture_Excurs",
      index: 19,
    },
    {
      label: "Mountain/Winter Sports",
      value: "Mountain_Winter_Spor",
      index: 20,
    },
    { label: "Nature & Wildlife", value: "Nature_and_Wildlife", index: 21 },
    // { label: "Other", value: "other", index: 22 },
  ];
  const checkboxRight = [
    {
      label: "Outdoor Fitness/Sports",
      value: "Outdoor_Fitness_Spor",
      index: 26,
    },
    {
      label: "Professional/Collegiate Sports",
      value: "Professional_and_Col",
      index: 24,
    },
    { label: "Shopping", value: "Shopping", index: 23 },
    { label: "Theatre & Fine Arts", value: "Theatre_Fine_Arts", index: 26 },
    { label: "Wine/Beer Enthusiast", value: "Wine_and_Beer_Enthus", index: 25 },
  ];

  return (
    <>
      <section className="my-profile-form">
        <div className="my-profile-form-contents">
          <div className="form-part">
            <div className="checkbox-container">
              <div className="checkbox-left">
                {checkboxLeft.map((label: any, index) => {
                  return (
                    <MPHCheckbox
                      label={label.label}
                      id={label.value}
                      key={index}
                      normalLabel={true}
                      value={
                        values.custom_fields[label.value] === "Yes"
                          ? true
                          : false
                      }
                      onChangeText={(text: any) => {
                        setFieldValue(
                          `custom_fields[${label.value}]`,
                          text === true ? "Yes" : "No"
                        );
                      }}
                    />
                  );
                })}
              </div>
              <div className="checkbox-right">
                {checkboxRight.map((label: any, index) => {
                  return (
                    <MPHCheckbox
                      label={label.label}
                      key={index}
                      id={label.value}
                      normalLabel={true}
                      value={
                        values.custom_fields[label.value] === "Yes"
                          ? true
                          : false
                      }
                      onChangeText={(text: any) => {
                        setFieldValue(
                          `custom_fields[${label.value}]`,
                          text === true ? "Yes" : "No"
                        );
                      }}
                    />
                  );
                })}
              </div>
            </div>
            {/* <MPHInput
              keyboardType="text"
              placeholder=""
              label="Please Specify"
              value={values.custom_fields.other_values}
              onChangeText={(text: any) => {
                handleChange("custom_fields.other_values")(text);
              }}
            /> */}
            {/* <br /> */}
            {/* <MPHCheckbox
              label={"I would like to receive personalized emails, including exclusive offers and promotions, from My Place Hotels of America."}
              key={"email"}
              id={"email"}
              normalLabel={true}
              value={
                values.subscriptions.subscription[0].is_subscribed === "0"
                  ? false
                  : true
              }
              onChangeText={(text: any) => {
                setFieldValue(
                  "subscriptions.subscription[0].is_subscribed",
                  text === true ? "1" : "0"
                );
              }}
            /> */}
            <div className="profile-form-button-container">
              <MPHButton
                buttonText="Previous"
                buttonType="secondary-button"
                key={11}
                disabled={isLoading}
                backward={true}
                onbuttonClick={() => prevStep()}
              />
              <MPHButton
                buttonText="Submit"
                buttonType="secondary-button"
                type="submit"
                isLoading={isLoading}
                key={12}
                onbuttonClick={() => submitFormData()}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MPHProfileFormThree;
