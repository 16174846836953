
const REACT_APP_APP_URL: any = process.env.REACT_APP_APP_URL;
const REACT_APP_API_URL: any = process.env.REACT_APP_API_URL;
const REACT_APP_CRM_URL: any = process.env.REACT_APP_CRM_URL;
const REACT_APP_CRM_ID: any = process.env.REACT_APP_CRM_ID;
const REACT_APP_CONTACT_EMAIL: any = process.env.REACT_APP_CONTACT_EMAIL;
const REACT_APP_CAP_API: any = process.env.REACT_APP_CAP_API;
const REACT_APP_BASIC_AUTH_TOKEN: any = process.env.REACT_APP_BASIC_AUTH_TOKEN;
const REACT_APP_BASIC_SSO_AUTH_TOKEN: any = process.env.REACT_APP_BASIC_SSO_AUTH_TOKEN;
const REACT_FIND_HOTEL_LINK: any = process.env.REACT_APP_FIND_HOTEL_LINK;
const REACT_BRAND_NAME: any = process.env.REACT_APP_BRAND;
const REACT_DEVICE_ID: any = process.env.REACT_APP_DEVICE_ID;
const REACT_CHAIN_ID: any = process.env.REACT_APP_CHAIN_ID;
const MPH_LINKEDIN_URL: string = "https://www.linkedin.com/company/my-place-hotel";
const MPH_FACEBOOK_URL: string = "https://www.facebook.com/MyPlaceHotels/";
const MPH_TWITTER_URL: string = "https://twitter.com/myplacehotels";
const MPH_INSTAGRAM_URL: string = "https://www.instagram.com/myplacehotels/";
const MPH_YOUTUBE_URL: string = "https://www.youtube.com/channel/UCcqUpbo4AZ9pdKzXSEgUYxA?nohtml5=False";
const MPH_PINTEREST_URL: string = "https://www.pinterest.com/myplacehotels/";
const MPH_WEB: string = "https://www.myplacehotels.com";
const MPH_VIEW_MODIFY_RESERVATIONS: string = "https://bookdirect.myplacehotels.com/signIn?chain=19777&level=chain&locale=en-US&sbe_ri=0&_gl=1*1yw08qa*_ga*OTMwNDA3NjYzLjE3MDI1NzYyNzI.*_ga_YB619GR0DE*MTcwNTA3ODA3Mi41NS4xLjE3MDUwNzg4NzUuNTYuMC4w";
const MPH_GROUP_RESERVATION: string = "https://www.myplacehotels.com/corporate-groups/group-travel";
const MPH_CORPORATE_AND_GROUP_TRAVEL: string = "https://www.myplacehotels.com/corporate-groups";
const MPH_CORPORATE_TRAVEL: string = "https://www.myplacehotels.com/corporate-groups/corporate-travel";
const MPH_STAY_REWARDED: string = "https://www.myplacehotels.com/stayrewarded";
const MPH_ABOUT_MY_PLACE: string = "https://www.myplacehotels.com/about";
const MPH_TRAVEL_UPDATE: string = "https://www.myplacehotels.com/about/travel-updates";
const MPH_CAREERS: string = "https://www.myplacehotels.com/about/careers";
const MPH_FRANCHISING: string = "https://www.myplacehotels.com/about/franchising";
const MPH_TREND_BY_MY_PLACE: string = "https://www.trendbymyplace.com";
const MPH_MY_PLACE_BLOG: string = "https://www.myplacehotels.com/travel-blog";
const MPH_OUR_LOCATIONS: string = "https://www.myplacehotels.com/locations";
const MPH_CONTACT_US: string = "https://www.myplacehotels.com/contact-us";
const MPH_SECURITY_PRIVACY: string = "https://www.myplacehotels.com/security-privacy";
const MPH_ACCESSIBILITY: string = "https://www.myplacehotels.com/accessibility";
const MPH_TERMS_AND_CONDITIONS: string = "https://www.myplacehotels.com/terms-conditions";
const MPH_EARN_POINTS: string = "https://www.myplacehotels.com/stayrewarded";
const MPH_SPECIAL_OFFERS: string = "https://www.myplacehotels.com/special-offers";



export const mimeMap = {
  pdf: 'application/pdf',
  mp4: 'video/mp4',
};

export const API = {
  Auth: 'auth/v1/',
  CAP: 'mobile/v2/api/',
  V1CUSTOMER: 'v1.1/customer/',
  V1POINTS: 'v1.1/points/',
  V2LEDGER: 'v2/pointsLedger/',
};

export const APP_URL = REACT_APP_APP_URL;
export const API_URL = REACT_APP_API_URL;
export const CRM_API = REACT_APP_CRM_URL;
export const HOTEL_FIND_LINK = REACT_FIND_HOTEL_LINK;
export const CAP_API = REACT_APP_CAP_API;
export const BASIC_AUTH = REACT_APP_BASIC_AUTH_TOKEN;
export const BASIC_SSO_AUTH = REACT_APP_BASIC_SSO_AUTH_TOKEN;
export const CRM_ID = REACT_APP_CRM_ID;
export const CONTACT_EMAIL = REACT_APP_CONTACT_EMAIL
export const DEVICE_ID = REACT_DEVICE_ID;
export const CHAIN_ID = REACT_CHAIN_ID;
export const BRAND_NAME = REACT_BRAND_NAME;
export const EXTERNAL_URLS = {
  "LinkedIn": MPH_LINKEDIN_URL,
  "FaceBook": MPH_FACEBOOK_URL,
  "Twitter": MPH_TWITTER_URL,
  "Instagram": MPH_INSTAGRAM_URL,
  "YouTube": MPH_YOUTUBE_URL,
  "Pinterest": MPH_PINTEREST_URL
}
export const INTERNAL_REDIRECTION_URLS = {
  MPH_VIEW_MODIFY_RESERVATIONS: MPH_VIEW_MODIFY_RESERVATIONS,
  MPH_GROUP_RESERVATION: MPH_GROUP_RESERVATION,
  MPH_CORPORATE_TRAVEL: MPH_CORPORATE_TRAVEL,
  MPH_STAY_REWARDED: MPH_STAY_REWARDED,
  MPH_ABOUT_MY_PLACE: MPH_ABOUT_MY_PLACE,
  MPH_TRAVEL_UPDATE: MPH_TRAVEL_UPDATE,
  MPH_CAREERS: MPH_CAREERS,
  MPH_FRANCHISING: MPH_FRANCHISING,
  MPH_TREND_BY_MY_PLACE: MPH_TREND_BY_MY_PLACE,
  MPH_MY_PLACE_BLOG: MPH_MY_PLACE_BLOG,
  MPH_OUR_LOCATIONS: MPH_OUR_LOCATIONS,
  MPH_CONTACT_US: MPH_CONTACT_US,
  MPH_SECURITY_PRIVACY: MPH_SECURITY_PRIVACY,
  MPH_ACCESSIBILITY: MPH_ACCESSIBILITY,
  MPH_TERMS_AND_CONDITIONS: MPH_TERMS_AND_CONDITIONS,
  Web: MPH_WEB,
  MPH_EARN_POINTS: MPH_EARN_POINTS,
  MPH_SPECIAL_OFFERS: MPH_SPECIAL_OFFERS,
  MPH_CORPORATE_AND_GROUP_TRAVEL: MPH_CORPORATE_AND_GROUP_TRAVEL
}
// console.log(APP_URL, 'H');


export const OPEN_ROUTES = [
  { label: 'Locations', link: INTERNAL_REDIRECTION_URLS.MPH_OUR_LOCATIONS },
  { label: 'Special Offers', link: INTERNAL_REDIRECTION_URLS.MPH_SPECIAL_OFFERS },
  { label: 'Corporate & Group Travel', link: INTERNAL_REDIRECTION_URLS.MPH_CORPORATE_AND_GROUP_TRAVEL },
];

export const AUTH_ROUTES = [
  { label: 'My Status', route: '/status' },
  { label: 'My Stays', route: '/stays' },
  { label: 'My Benefits', route: '/benefits' },
  { label: 'Earn', route: '/earn' },
  { label: 'Redeem', route: '/redeem' },
  { label: 'My Profile', route: '/profile' },
  { label: 'Contact Us', route: '/contact' },
];

export const COUNTRIES: any = [
  {
    name: "United States",
    code: "US",
  },
  {
    name: "Canada",
    code: "CA",
  },
  {
    name: "Afghanistan",
    code: "AF",
  },
  {
    name: "Albania",
    code: "AL",
  },
  {
    name: "Algeria",
    code: "DZ",
  },
  {
    name: "American Samoa",
    code: "AS",
  },
  {
    name: "Andorra",
    code: "AD",
  },
  {
    name: "Angola",
    code: "AO",
  },
  {
    name: "Anguilla",
    code: "AI",
  },
  {
    name: "Antarctica",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
  },
  {
    name: "Argentina",
    code: "AR",
  },
  {
    name: "Armenia",
    code: "AM",
  },
  {
    name: "Aruba",
    code: "AW",
  },
  {
    name: "Australia",
    code: "AU",
  },
  {
    name: "Austria",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    code: "AZ",
  },
  {
    name: "Bahamas",
    code: "BS",
  },
  {
    name: "Bahrain",
    code: "BH",
  },
  {
    name: "Bangladesh",
    code: "BD",
  },
  {
    name: "Barbados",
    code: "BB",
  },
  {
    name: "Belarus",
    code: "BY",
  },
  {
    name: "Belgium",
    code: "BE",
  },
  {
    name: "Belize",
    code: "BZ",
  },
  {
    name: "Benin",
    code: "BJ",
  },
  {
    name: "Bermuda",
    code: "BM",
  },
  {
    name: "Bhutan",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    code: "BO",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    code: "BQ",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    name: "Botswana",
    code: "BW",
  },
  {
    name: "Bouvet Island",
    code: "BV",
  },
  {
    name: "Brazil",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    code: "BN",
  },
  {
    name: "Bulgaria",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    code: "BF",
  },
  {
    name: "Burundi",
    code: "BI",
  },
  {
    name: "Cambodia",
    code: "KH",
  },
  {
    name: "Cameroon",
    code: "CM",
  },
  {
    name: "Cape Verde",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    code: "KY",
  },
  {
    name: "Central African Republic",
    code: "CF",
  },
  {
    name: "Chad",
    code: "TD",
  },
  {
    name: "Chile",
    code: "CL",
  },
  {
    name: "China",
    code: "CN",
  },
  {
    name: "Christmas Island",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
  },
  {
    name: "Colombia",
    code: "CO",
  },
  {
    name: "Comoros",
    code: "KM",
  },
  {
    name: "Congo",
    code: "CG",
  },
  {
    name: "Congo, the Democratic Republic of the",
    code: "CD",
  },
  {
    name: "Cook Islands",
    code: "CK",
  },
  {
    name: "Costa Rica",
    code: "CR",
  },
  {
    name: "Croatia",
    code: "HR",
  },
  {
    name: "Cuba",
    code: "CU",
  },
  {
    name: "Curaçao",
    code: "CW",
  },
  {
    name: "Cyprus",
    code: "CY",
  },
  {
    name: "Czech Republic",
    code: "CZ",
  },
  {
    name: "Côte d'Ivoire",
    code: "CI",
  },
  {
    name: "Denmark",
    code: "DK",
  },
  {
    name: "Djibouti",
    code: "DJ",
  },
  {
    name: "Dominica",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    code: "DO",
  },
  {
    name: "Ecuador",
    code: "EC",
  },
  {
    name: "Egypt",
    code: "EG",
  },
  {
    name: "El Salvador",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
  },
  {
    name: "Eritrea",
    code: "ER",
  },
  {
    name: "Estonia",
    code: "EE",
  },
  {
    name: "Ethiopia",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    code: "FO",
  },
  {
    name: "Fiji",
    code: "FJ",
  },
  {
    name: "Finland",
    code: "FI",
  },
  {
    name: "France",
    code: "FR",
  },
  {
    name: "French Guiana",
    code: "GF",
  },
  {
    name: "French Polynesia",
    code: "PF",
  },
  {
    name: "French Southern Territories",
    code: "TF",
  },
  {
    name: "Gabon",
    code: "GA",
  },
  {
    name: "Gambia",
    code: "GM",
  },
  {
    name: "Georgia",
    code: "GE",
  },
  {
    name: "Germany",
    code: "DE",
  },
  {
    name: "Ghana",
    code: "GH",
  },
  {
    name: "Gibraltar",
    code: "GI",
  },
  {
    name: "Greece",
    code: "GR",
  },
  {
    name: "Greenland",
    code: "GL",
  },
  {
    name: "Grenada",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    code: "GP",
  },
  {
    name: "Guam",
    code: "GU",
  },
  {
    name: "Guatemala",
    code: "GT",
  },
  {
    name: "Guernsey",
    code: "GG",
  },
  {
    name: "Guinea",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
  },
  {
    name: "Guyana",
    code: "GY",
  },
  {
    name: "Haiti",
    code: "HT",
  },
  {
    name: "Heard Island and McDonald Islands",
    code: "HM",
  },
  {
    name: "Holy See (Vatican City State)",
    code: "VA",
  },
  {
    name: "Honduras",
    code: "HN",
  },
  {
    name: "Hong Kong",
    code: "HK",
  },
  {
    name: "Hungary",
    code: "HU",
  },
  {
    name: "Iceland",
    code: "IS",
  },
  {
    name: "India",
    code: "IN",
  },
  {
    name: "Indonesia",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of",
    code: "IR",
  },
  {
    name: "Iraq",
    code: "IQ",
  },
  {
    name: "Ireland",
    code: "IE",
  },
  {
    name: "Isle of Man",
    code: "IM",
  },
  {
    name: "Israel",
    code: "IL",
  },
  {
    name: "Italy",
    code: "IT",
  },
  {
    name: "Jamaica",
    code: "JM",
  },
  {
    name: "Japan",
    code: "JP",
  },
  {
    name: "Jersey",
    code: "JE",
  },
  {
    name: "Jordan",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    code: "KZ",
  },
  {
    name: "Kenya",
    code: "KE",
  },
  {
    name: "Kiribati",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of",
    code: "KP",
  },
  {
    name: "Korea, Republic of",
    code: "KR",
  },
  {
    name: "Kuwait",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
  },
  {
    name: "Lao People's Democratic Republic",
    code: "LA",
  },
  {
    name: "Latvia",
    code: "LV",
  },
  {
    name: "Lebanon",
    code: "LB",
  },
  {
    name: "Lesotho",
    code: "LS",
  },
  {
    name: "Liberia",
    code: "LR",
  },
  {
    name: "Libya",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    code: "LI",
  },
  {
    name: "Lithuania",
    code: "LT",
  },
  {
    name: "Luxembourg",
    code: "LU",
  },
  {
    name: "Macao",
    code: "MO",
  },
  {
    name: "Macedonia, the Former Yugoslav Republic of",
    code: "MK",
  },
  {
    name: "Madagascar",
    code: "MG",
  },
  {
    name: "Malawi",
    code: "MW",
  },
  {
    name: "Malaysia",
    code: "MY",
  },
  {
    name: "Maldives",
    code: "MV",
  },
  {
    name: "Mali",
    code: "ML",
  },
  {
    name: "Malta",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    code: "MH",
  },
  {
    name: "Martinique",
    code: "MQ",
  },
  {
    name: "Mauritania",
    code: "MR",
  },
  {
    name: "Mauritius",
    code: "MU",
  },
  {
    name: "Mayotte",
    code: "YT",
  },
  {
    name: "Mexico",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of",
    code: "FM",
  },
  {
    name: "Moldova, Republic of",
    code: "MD",
  },
  {
    name: "Monaco",
    code: "MC",
  },
  {
    name: "Mongolia",
    code: "MN",
  },
  {
    name: "Montenegro",
    code: "ME",
  },
  {
    name: "Montserrat",
    code: "MS",
  },
  {
    name: "Morocco",
    code: "MA",
  },
  {
    name: "Mozambique",
    code: "MZ",
  },
  {
    name: "Myanmar",
    code: "MM",
  },
  {
    name: "Namibia",
    code: "NA",
  },
  {
    name: "Nauru",
    code: "NR",
  },
  {
    name: "Nepal",
    code: "NP",
  },
  {
    name: "Netherlands",
    code: "NL",
  },
  {
    name: "New Caledonia",
    code: "NC",
  },
  {
    name: "New Zealand",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    code: "NI",
  },
  {
    name: "Niger",
    code: "NE",
  },
  {
    name: "Nigeria",
    code: "NG",
  },
  {
    name: "Niue",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
  },
  {
    name: "Norway",
    code: "NO",
  },
  {
    name: "Oman",
    code: "OM",
  },
  {
    name: "Pakistan",
    code: "PK",
  },
  {
    name: "Palau",
    code: "PW",
  },
  {
    name: "Palestine, State of",
    code: "PS",
  },
  {
    name: "Panama",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    code: "PG",
  },
  {
    name: "Paraguay",
    code: "PY",
  },
  {
    name: "Peru",
    code: "PE",
  },
  {
    name: "Philippines",
    code: "PH",
  },
  {
    name: "Pitcairn",
    code: "PN",
  },
  {
    name: "Poland",
    code: "PL",
  },
  {
    name: "Portugal",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    code: "PR",
  },
  {
    name: "Qatar",
    code: "QA",
  },
  {
    name: "Romania",
    code: "RO",
  },
  {
    name: "Russian Federation",
    code: "RU",
  },
  {
    name: "Rwanda",
    code: "RW",
  },
  {
    name: "Réunion",
    code: "RE",
  },
  {
    name: "Saint Barthélemy",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    code: "LC",
  },
  {
    name: "Saint Martin (French part)",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
  },
  {
    name: "Samoa",
    code: "WS",
  },
  {
    name: "San Marino",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
  },
  {
    name: "Senegal",
    code: "SN",
  },
  {
    name: "Serbia",
    code: "RS",
  },
  {
    name: "Seychelles",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    code: "SL",
  },
  {
    name: "Singapore",
    code: "SG",
  },
  {
    name: "Sint Maarten (Dutch part)",
    code: "SX",
  },
  {
    name: "Slovakia",
    code: "SK",
  },
  {
    name: "Slovenia",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    code: "SB",
  },
  {
    name: "Somalia",
    code: "SO",
  },
  {
    name: "South Africa",
    code: "ZA",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
  },
  {
    name: "South Sudan",
    code: "SS",
  },
  {
    name: "Spain",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    code: "LK",
  },
  {
    name: "Sudan",
    code: "SD",
  },
  {
    name: "Suriname",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
  },
  {
    name: "Swaziland",
    code: "SZ",
  },
  {
    name: "Sweden",
    code: "SE",
  },
  {
    name: "Switzerland",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    code: "SY",
  },
  {
    name: "Taiwan, Province of China",
    code: "TW",
  },
  {
    name: "Tajikistan",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of",
    code: "TZ",
  },
  {
    name: "Thailand",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    code: "TL",
  },
  {
    name: "Togo",
    code: "TG",
  },
  {
    name: "Tokelau",
    code: "TK",
  },
  {
    name: "Tonga",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
  },
  {
    name: "Tunisia",
    code: "TN",
  },
  {
    name: "Turkey",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
  },
  {
    name: "Tuvalu",
    code: "TV",
  },
  {
    name: "Uganda",
    code: "UG",
  },
  {
    name: "Ukraine",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
  },
  {
    name: "United Kingdom",
    code: "GB",
  },
  {
    name: "United States Minor Outlying Islands",
    code: "UM",
  },
  {
    name: "Uruguay",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    code: "VE",
  },
  {
    name: "Viet Nam",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
  },
  {
    name: "Western Sahara",
    code: "EH",
  },
  {
    name: "Yemen",
    code: "YE",
  },
  {
    name: "Zambia",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
  },
  {
    name: "Åland Islands",
    code: "AX",
  },
];
export const PROVINCES: any = {
  "United States": [
    { code: "AK", name: "Alaska" },
    { code: "AL", name: "Alabama" },
    { code: "AR", name: "Arkansas" },
    { code: "AZ", name: "Arizona" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DC", name: "District Of Columbia" },
    { code: "DE", name: "Delaware" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "GU", name: "Guam" },
    { code: "HI", name: "Hawaii" },
    { code: "IA", name: "Iowa" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "MA", name: "Massachusetts" },
    { code: "MD", name: "Maryland" },
    { code: "ME", name: "Maine" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MO", name: "Missouri" },
    { code: "MS", name: "Mississippi" },
    { code: "MT", name: "Montana" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "NE", name: "Nebraska" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NV", name: "Nevada" },
    { code: "NY", name: "New York" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "PR", name: "Puerto Rico" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VA", name: "Virginia" },
    { code: "VI", name: "Virgin Islands" },
    { code: "VT", name: "Vermont" },
    { code: "WA", name: "Washington" },
    { code: "WI", name: "Wisconsin" },
    { code: "WV", name: "West Virginia" },
    { code: "WY", name: "Wyoming" },
  ],
  Canada: [
    { code: "AB", name: "Alberta" },
    { code: "BC", name: "British Columbia" },
    { code: "MB", name: "Manitoba" },
    { code: "NB", name: "New Brunswick" },
    { code: "NL", name: "Newfoundland and Labrador" },
    { code: "NS", name: "Nova Scotia" },
    { code: "NT", name: "Northwest Territories" },
    { code: "NU", name: "Nunavut" },
    { code: "ON", name: "Ontario" },
    { code: "PE", name: "Prince Edward Island" },
    { code: "QC", name: "Quebec" },
    { code: "SK", name: "Saskatchewan" },
    { code: "YT", name: "Yukon" },
  ],
};
