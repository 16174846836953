import React, { useState } from "react";
import MPHInput from "src/components/DataProviders/MPHInput";
import MPHButton from "../MPHButtons/MPHButton";
import "./MPHForgotPassword.scss";
import modalClose from "../../../assets/images/modalClose.png";
import MPHOtp from "../MPHOtp/MPHOtp";
import {
  forGotPasswordSchema,
  forgotPasswordValidation,
} from "src/utils/MPHFormsSchemaValidations/MPHFormsSchemaValidations";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import { useFormik } from "formik";
import { errorToast, successToast } from "src/utils/toast";
import { BRAND_NAME, DEVICE_ID } from "src/config/constants";

const MPHForgotPassword = ({ onModalClose, isModal = true }: any) => {
  const [isOtp, setIsOtp] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, settoken] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [initialValues] = useState(forGotPasswordSchema);

  const { forGotPasswordChange, tokenGenerate, otpGenerate, otpValidate } =
    useApiAxiosRequests();

  const tokenGenerateFunc = async () => {
    const payload = {
      identifierType: "EMAIL",
      identifierValue: values.identifierValue.toLowerCase(),
      deviceId: DEVICE_ID,
      brand: BRAND_NAME,
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    const res: any = await tokenGenerate(payload);
    if (res.data && res.data.status && res.data.status.success) {
      settoken(res.data.auth.token);
      setSessionId(res.data.user.sessionId);
    }
    return {
      status: res.data && res.data.status && res.data.status.success,
      data: res.data,
    };
  };

  const handleSaveNext = async () => {
    setIsLoading(true);
    const data: any = await tokenGenerateFunc();
    if (!data.status) {
      errorToast(
        (data.data && data.data.status && data.data.status.message) ||
          "User does not exists"
      );
      setIsLoading(false);
      return;
    }

    if (!data.data.user.userRegisteredForPassword) {
      errorToast(`If this is your first login, click "First time signing in?" to set up your account. Already registered but can't log in? Use the "Forgot Your Password?" option.`);
      setIsLoading(false);
      return;
    }

    const payload = {
      identifierType: "EMAIL",
      identifierValue: values.identifierValue.toLowerCase(),
      deviceId: values.deviceId,
      brand: values.brand,
      password: values.password,
      confirmPassword: values.confirmPassword,
      sessionId: data.data.user.sessionId,
    };

    const forgotPassword: any = await forGotPasswordChange(payload);
    if (
      forgotPassword.data &&
      forgotPassword.data.status &&
      forgotPassword.data.status.success === false
    ) {
      errorToast(
        (forgotPassword.data && forgotPassword.data.status && forgotPassword.data.status.message) ||
          "User does not exists"
      );
      setIsLoading(false);
      return;
    }

    const res: any = await otpGenerate(payload);
    setIsLoading(false);
    if (res.data && res.data.status && res.data.status.success) {
      setIsLoading(false);
      setIsPassword(false);
      setIsOtp(true);
      successToast(res.data.status.message);
    } else {
      errorToast(
        (res && res.data && res.data.status && res.data.status.message) ||
          "Error generating otp, Please try again"
      );
    }
  };

  const forgotPasswordOtpValidateFunc = async (otp: any) => {
    const payload = {
      identifierType: "EMAIL",
      identifierValue: values.identifierValue.toLowerCase(),
      deviceId: values.deviceId,
      brand: values.brand,
      sessionId: sessionId,
      password: values.password,
      confirmPassword: values.confirmPassword,
      otp: otp,
    };
    setIsLoading(true);
    const res: any = await otpValidate(payload);
    setIsLoading(false);
    if (res.data && res.data.status && res.data.status.success) {
      onModalClose();
      successToast(res.data.status.message);
    } else {
      errorToast(
        (res && res.data && res.data.status && res.data.status.message) ||
          "Error Validating otp, Please try again"
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordValidation,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleSaveNext,
  });
  const { values, handleChange, handleBlur, errors, touched, isValid } = formik;

  return (
    <>
      {isPassword ? (
        <>
          <div className="modal-content form-modal">
            <div className="row">
              <section className="mb-3">
                <h2 className="form-header">Forgot Password</h2>
                {isModal ? (
                  <img
                    className="close-modal"
                    src={modalClose}
                    alt=""
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsOtp(false);
                      onModalClose();
                    }}
                  />
                ) : (
                  <img
                    className="close-modal"
                    src={modalClose}
                    alt=""
                    onClick={() => {
                      setIsOtp(false);
                      onModalClose();
                    }}
                  />
                )}
              </section>
              <div className="col-12 mt-3">
                <MPHInput
                  placeholder="Enter New Password"
                  keyboardType={"password"}
                  value={values.password}
                  isModal={true}
                  onBlur={handleBlur("password")}
                  onChangeText={(text: any) => {
                    handleChange("password")(text);
                  }}
                  isError={touched.password! && !!errors.password}
                  error={errors.password}
                />
                <span className="password-helper mb-3">
                  The password must be at least 8 characters and contain at
                  least one uppercase character.
                </span>
              </div>
              <div className="col-12 mt-3">
                <MPHInput
                  keyboardType={"password"}
                  placeholder="Enter Confirm Password"
                  value={values.confirmPassword}
                  isModal={true}
                  onBlur={handleBlur("confirmPassword")}
                  onChangeText={(text: any) => {
                    handleChange("confirmPassword")(text);
                  }}
                  isError={touched.confirmPassword! && !!errors.confirmPassword}
                  error={errors.confirmPassword}
                />
              </div>
              <div className="col-12 mt-4">
                <MPHButton
                  disabled={!isValid || isLoading}
                  isLoading={isLoading}
                  buttonText="Submit"
                  onbuttonClick={() => handleSaveNext()}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {!isOtp ? (
            <div className="modal-content form-modal">
              <div className="row">
                <section className="mb-3">
                  <h2 className="form-header">Forgot Password</h2>
                  {isModal ? (
                    <img
                      className="close-modal"
                      src={modalClose}
                      alt=""
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setIsOtp(false);
                        onModalClose();
                      }}
                    />
                  ) : (
                    <img
                      className="close-modal"
                      src={modalClose}
                      alt=""
                      onClick={() => {
                        setIsOtp(false);
                        onModalClose();
                      }}
                    />
                  )}
                </section>
                <div className="col-12 mt-3">
                  <MPHInput
                    keyboardType="email"
                    placeholder="Email"
                    value={values.identifierValue}
                    isModal={true}
                    onBlur={handleBlur("identifierValue")}
                    onChangeText={(text: any) => {
                      handleChange("identifierValue")(text);
                    }}
                    isError={
                      touched.identifierValue! && !!errors.identifierValue
                    }
                    error={errors.identifierValue}
                  />
                </div>
                <div className="col-12 mt-4">
                  <MPHButton
                    disabled={errors.identifierValue}
                    isLoading={isLoading}
                    buttonText="Submit"
                    onbuttonClick={() => setIsPassword(true)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <MPHOtp
              text={values.identifierValue}
              isLoading={isLoading}
              darkTheme={true}
              resend={handleSaveNext}
              isModal={isModal}
              onClose={onModalClose}
              onOtp={(otp: any) => forgotPasswordOtpValidateFunc(otp)}
            />
          )}
        </>
      )}
    </>
  );
};

export default MPHForgotPassword;
