import React from "react";
import "./Common.scss";

type DropDownProps = {
  disabled?: boolean;
  label?: string;
  value?: any;
  onChangeText: (t: any) => void;
  isError?: any;
  error?: any;
  id?: any;
  registerInput?: any;
  normalLabel?: boolean;
  isModal?: any;
};

const MPHCheckbox = ({
  label,
  onChangeText,
  value,
  disabled = false,
  isError,
  id,
  error,
  isModal,
  registerInput = {},
  normalLabel = false,
}: DropDownProps) => {
  return (
    <>
      <section>
        <div className="form-check pl-0">
          <input
            className="form-check-input mb-1"
            type="checkbox"
            value={value}
            checked={value}
            disabled={disabled}
            onChange={($event: any) => onChangeText($event?.target?.checked)}
            id={registerInput ? { ...registerInput }.name : id}
            {...registerInput}
          />
          <label
            className={
              normalLabel
                ? "normal-label"
                : isModal
                ? "modal-label"
                : "form-label"
            }
            htmlFor={id}
          >
            {label}
          </label>
        </div>

        {isError && <p className="error-code">{error}</p>}
      </section>
    </>
  );
};

export default MPHCheckbox;
