import React from "react";
import "./Common.scss";

const MPHStepper = ({
    activeIndex = 0,
    setFormStep = {}
} : any) => {
    const steps = [
        {
            stepNumber: 1,
            text: "DETAILS"
        }, {
            stepNumber: 2,
            text: "PREFERENCES"
        }, {
            stepNumber: 3,
            text: "INTERESTS"
        }
    ]
    return (
        <>
            <div className="stepper d-flex">
                {
                steps.map((step, index) => {
                    return (
                        <>
                            <div key={step.stepNumber} className="step-container d-flex flex-column align-items-center">
                                <h1 className={
                                        "m-0 step" + (
                                        activeIndex === index ? " active" : ""
                                    )
                                    }
                                    onClick={
                                        () => {
                                            setFormStep(index);
                                        }
                                }>
                                    {
                                    step.stepNumber
                                }</h1>
                                <h4 className="s-label m-0 mt-2">
                                    {
                                    step.text
                                }</h4>
                            </div>
                            {
                            index !== steps.length - 1 && <div className="divider"></div>
                        } 
                      </>
                    )
                })
            } </div>

        </>
    );
};

export default MPHStepper;
