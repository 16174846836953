/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ArcProgress from "react-arc-progress";
import userLogo from "../../../assets/images/user-white.png";
import "./MPHStatusComponent.scss";
import MPHButton from "../MPHButtons/MPHButton";
import useAuth from "src/context/AuthContext";
import moment from "moment";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import { useStorage } from "src/utils/storage";
import { useNavigate } from "react-router-dom";
import { useBlockUIContext } from "ns-react-block-ui";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const MPHStatusComponent = () => {
  const { user } = useAuth();
  const [status, setStatus]: any = useState({});
  const [progress, setProgress]: any = useState(0);
  const { setBlockUI } = useBlockUIContext();
  const navigate = useNavigate();
  const { getCustomerByEmailLoggedIn } = useApiAxiosRequests();
  const storage = useStorage();
  const location = useLocation();

  const arcFillColor = {
    gradient: ["#2D5E97"],
  };
  const percentageText = [
    {
      text: "%",
      size: "16px",
      color: "white",
      x: 120,
      y: 100,
    },
  ];

  const getStatusFunc = async () => {
    const store: any = storage.get("user_profile");
    if (store && JSON.parse(store) && JSON.parse(store).email) {
      let payload: any = {
        email: JSON.parse(store).email,
      };
      setBlockUI({ blocking: true });
      const res: any = await getCustomerByEmailLoggedIn(payload);
      setBlockUI({ blocking: false });
      if (
        res &&
        res.data &&
        res.data.status &&
        res.data.status.success === "true"
      ) {
        setStatus(
          res.data.customers &&
            res.data.customers.customer &&
            res.data.customers.customer.length &&
            res.data.customers.customer[0]
        );
        const customer = res.data.customers &&
        res.data.customers.customer &&
        res.data.customers.customer.length &&
        res.data.customers.customer[0];
        let count = 0;
        let firstCount = 0;
        let secondcount = 0;
        let thirdcount = 0;
        if(customer.firstname) {
          count = count + 0.1;
        }
        if(customer.lastname) {
          count = count + 0.1;
        }
        if(customer.email) {
          count = count + 0.1;
        }
        if(customer.mobile) {
          count = count + 0.1;
        }
        if (
          res.data.customers.customer[0].extended_fields &&
          res.data.customers.customer[0].extended_fields.field &&
          res.data.customers.customer[0].extended_fields.field.length
        ) {
          res.data.customers.customer[0].extended_fields.field.forEach(
            (res: any) => {
              if (
                ["country_of_residence", "city", "state", "zip"].includes(
                  res.name
                )
              ) {
                if (res.value) {
                  firstCount = 0.3;
                }
              }
            }
          );
        }
        if (
          res.data.customers.customer[0].custom_fields &&
          res.data.customers.customer[0].custom_fields.field &&
          res.data.customers.customer[0].custom_fields.field.length
        ) {
          res.data.customers.customer[0].custom_fields.field.forEach(
            (res: any) => {
              if (
                [
                  "Ice_Maker_Required",
                  "room_type",
                  "floor_preference",
                  "near_elevator",
                  "travel_with_pets",
                  "type_of_pet",
                  "reason_for_travel",
                ].includes(res.name)
              ) {
                if (res.value) {
                  secondcount =  0.15;
                }
              } else if (
                [
                  "Kids_Activities",
                  "Cooking",
                  "Live_Music_and_Conce",
                  "Local_Culture_Excurs",
                  "Mountain_Winter_Spor",
                  "Outdoor_Fitness_Spor",
                  "Nature_and_Wildlife",
                  "Professional_and_Col",
                  "Shopping",
                  "Theatre_Fine_Arts",
                  "Wine_and_Beer_Enthus",
                ].includes(res.name)
              ) {
                if (res.value) {
                  thirdcount = 0.15;
                }
              }
            }
          );
        }
        count = count + firstCount + secondcount + thirdcount;
        const data =
          Number((count).toFixed(1)) > 1 ? 1 : count.toFixed(1);
        setProgress(data);
      } else {
        setStatus(null);
      }
    } else {
    }
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    getStatusFunc();
  }, []);

  return (
    <>
      <div className="my-status-header">
        <h1 className="text-left text-white m-0">My Status</h1>
      </div>
      <div className="my-status-contents d-flex align-items-center justify-content-evenly">
        <div className="user-details d-flex flex-column align-items-center justify-content-center">
          <div className="user-details-content d-flex align-items-center">
            <div className="user-logo">
              <img src={userLogo} alt="" />
            </div>
            <div className="user-name">
              <p className="m-0">Hello, {user?.profileName}</p>
            </div>
          </div>
          <div className="user-id">
            <p className="m-0">Member #{status?.external_id || ""}</p>
            <p className="m-0">
              Member Since:{" "}
              {status?.registered_on
                ? moment(status?.registered_on).format("MM-DD-YYYY")
                : ""}
            </p>
          </div>
        </div>
        <div className="progress-chart p-r">
          <p className="m-0">PROFILE COMPLETION</p>
          <ArcProgress
            thickness={25}
            fillColor={arcFillColor}
            progress={progress}
            fillThickness={15}
            arcStart={180}
            arcEnd={360}
            className="arc-progress"
            text={`${progress * 100}`}
            size={200}
            customText={percentageText}
            textStyle={{ color: "white" }}
            style={{ position: "relative" }}
          ></ArcProgress>
          {progress >= 1 ? (
            <></>
          ) : (
            <p
              onClick={() => navigate("/profile")}
              className="complete-profile"
            >
              Complete your profile
            </p>
          )}
        </div>
        <div className="user-points d-flex flex-column ">
          <div className="points d-flex">
            <h2 className="m-0">
              {(user?.loyalty_points || 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </h2>
            <span>POINTS</span>
          </div>
          <MPHButton
            buttonText="REDEEM POINTS"
            onbuttonClick={() => {
              navigate("/redeem");
            }}
          />
          <MPHButton
            buttonText="Book Now to Earn"
            onbuttonClick={() => {
              navigate("/find-hotels");
              window.scrollTo(0, 0);
            }}
          />
        </div>
      </div>
      <button
        hidden
        data-bs-toggle="modal"
        id="ModalDetailsStatus"
        data-bs-target="#ModalDetails"
      ></button>
    </>
  );
};

export default MPHStatusComponent;
