/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MPHInput from "src/components/DataProviders/MPHInput";
import modalClose from "../../../assets/images/modalClose.png";
import right from "../../../assets/images/right.svg";
import MPHButton from "../MPHButtons/MPHButton";
import "react-toastify/dist/ReactToastify.css";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import {
  passwordChangeSchema,
  passwordChangeValidation,
} from "src/utils/MPHFormsSchemaValidations/MPHFormsSchemaValidations";
import { useFormik } from "formik";
import { useStorage } from "src/utils/storage";
import { errorToast } from "src/utils/toast";
import "./MPHChangePassword.scss";
import MPHForgotPassword from "../MPHForgotPassword/MPHForgotPassword";

const MPHChangePassword = () => {
  const [initialValues] = useState(passwordChangeSchema);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isForgot, setIsForgot] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const storage = useStorage();
  const { passwordChange } = useApiAxiosRequests();

  const onSuccess = () => {
    const modal = document.getElementById("forGotPassword");
    const bm = document.getElementsByClassName("modal-backdrop");
    const mp = document.getElementsByClassName("modal-open");
    if (modal && modal.classList.contains("show")) {
      modal.classList.remove("show");
      modal.style.display = "none";
    }
    if (bm && bm.length && bm[0].classList.contains("show")) {
      bm[0].classList.remove("show");
      bm[0].classList.remove("modal-backdrop");
    }
    if (mp && mp.length && mp[0].classList.contains("modal-open")) {
      mp[0].classList.remove("modal-open");
    }
    setIsForgot(false);
    setIsChanged(false);
  };

  const submitFormData = async () => {
    const payload = {
      ...values,
      token,
    };
    payload.identifierValue = email.toLowerCase();
    setIsLoading(true);
    const data: any = await passwordChange(payload);
    setIsLoading(false);
    if (data.data && data.data.status && data.data.status.success) {
      setIsChanged(true);
    } else {
      errorToast(
        (data && data.data && data.data.status && data.data.status.message) ||
          "Unable to change password, please try again"
      );
    }
  };

  useEffect(() => {
    const store: any = storage.get("user_profile");
    if (store && JSON.parse(store) && JSON.parse(store).email) {
      setEmail(JSON.parse(store).email);
      setToken(JSON.parse(store).token);
    }
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: passwordChangeValidation,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: submitFormData,
  });

  const { values, handleChange, handleBlur, errors, touched, isValid } = formik;

  return (
    <>
      <div
        className="modal fade"
        id="forGotPassword"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="forGotPasswordLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          {!isForgot ? (
            <div className="modal-content form-modal">
              {!isChanged ? (
                <div className="row">
                  <section className="mb-3">
                    <h2 className="form-header">Update Password</h2>
                    <img
                      className="close-modal"
                      src={modalClose}
                      alt=""
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </section>
                  <div className="col-12 mt-3">
                    <label htmlFor="" className="form-label">
                      OLD PASSWORD
                    </label>
                    <MPHInput
                      placeholder=""
                      keyboardType={"password"}
                      value={values.password}
                      isModal={true}
                      onBlur={handleBlur("password")}
                      onChangeText={(text: any) => {
                        handleChange("password")(text);
                      }}
                      isError={touched.password! && !!errors.password}
                      error={errors.password}
                    />
                    <p
                      onClick={() => setIsForgot(true)}
                      className="password-forgot mb-0 mt-2"
                    >
                      Forgot Password
                    </p>
                  </div>
                  <div className="col-12 mt-3">
                    <label htmlFor="" className="form-label">
                      NEW PASSWORD
                    </label>
                    <MPHInput
                      placeholder=""
                      keyboardType={"password"}
                      value={values.newPassword}
                      isModal={true}
                      onBlur={handleBlur("newPassword")}
                      onChangeText={(text: any) => {
                        handleChange("newPassword")(text);
                      }}
                      isError={touched.newPassword! && !!errors.newPassword}
                      error={errors.newPassword}
                    />
                    <p className="password-helper mb-0 mt-2">
                      The password must be at least 8 characters and contain at
                      least one uppercase character.
                    </p>
                  </div>
                  <div className="col-12 mt-3">
                    <label htmlFor="" className="form-label">
                      CONFIRM NEW PASSWORD
                    </label>
                    <MPHInput
                      keyboardType={"password"}
                      placeholder=""
                      value={values.confirmPassword}
                      isModal={true}
                      onBlur={handleBlur("confirmPassword")}
                      onChangeText={(text: any) => {
                        handleChange("confirmPassword")(text);
                      }}
                      isError={
                        touched.confirmPassword! && !!errors.confirmPassword
                      }
                      error={errors.confirmPassword}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <MPHButton
                      disabled={!isValid || isLoading}
                      isLoading={isLoading}
                      buttonText="Submit"
                      onbuttonClick={() => submitFormData()}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <section className="mb-3">
                    <img
                      src={right}
                      alt=""
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </section>
                  <section className="mb-3 mt-3">
                    <h3 className="reset-password">
                      Your Password has <br /> been reset.
                    </h3>
                  </section>
                  <div className="col-12 mt-4">
                    <MPHButton
                      buttonText="BACK TO MY PROFILE"
                      onbuttonClick={() => onSuccess()}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <MPHForgotPassword
              onModalClose={() => {
                onSuccess();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MPHChangePassword;
