/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./MPHFindHotels.scss";
import datePicker from "../../../assets/images/date_picker_yellow.png";
import locationPicker from "../../../assets/images/location_picker_yellow.png";
import MPHButton from "../MPHButtons/MPHButton";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import { useFormik } from "formik";
import MPHDropdown from "src/components/DataProviders/MPHDropdown";
import MPHInput from "src/components/DataProviders/MPHInput";
import { DatePicker } from "antd";
import { Select } from "antd";
import { useStorage } from "src/utils/storage";
import { findHotelValidation } from "src/utils/MPHFormsSchemaValidations/MPHFormsSchemaValidations";
import { errorToast } from "src/utils/toast";
import moment from "moment";
import {
  BASIC_SSO_AUTH,
  CHAIN_ID,
  HOTEL_FIND_LINK,
} from "src/config/constants";

const MPHFindHotels = () => {
  const {
    getHotelsList,
    getCustomerLookUp,
    getCustomerLookUpAuthToken,
    getCRMSession,
  } = useApiAxiosRequests();

  const [isLoading, setIsLoading] = useState(false);
  const [isMemberChecked, setIsMemberChecked] = useState(false);
  const { RangePicker } = DatePicker;
  const [hotelsList, setHotelsList] = useState([]);
  const storage = useStorage();

  const getCustomerLookUpFunc = async (id?: any) => {
    let params: any = {};
    const store: any = storage.get("user_profile");
    if (store && JSON.parse(store) && JSON.parse(store).email) {
      params = {
        identifierName: "email",
        identifierValue: JSON.parse(store).email.toLowerCase(),
      };
      setIsLoading(true);
      const res: any = await getCustomerLookUp(params);
      // const user: any = await getCustomerByEmailLoggedIn({
      //   email: JSON.parse(store).email,
      // });
      if (res && res.data && res.data.id) {
        const token: any = await getCustomerLookUpAuthToken(
          {
            GrantType: "CLIENT_CREDENTIALS",
            UserIPAddress: "127.0.0.1",
            UserType: "Hotel",
            BusinessContext: "WBSVC",
          },
          BASIC_SSO_AUTH,
          JSON.parse(store).email.toLowerCase()
        );
        if (token.data && token.data.access_token) {
          let memberId;
          if (
            res &&
            res.data &&
            res.data.profiles &&
            res.data.profiles.length &&
            res.data.profiles[0].identifiers &&
            res.data.profiles[0].identifiers.length
          ) {
            memberId = res.data.profiles[0].identifiers.find(
              (val: any) => val.type === "externalId"
            ).value;
          }
          const crmSession: any = await getCRMSession(
            {
              ChainId: CHAIN_ID,
              IDContext: "CRM",
              ProfileID: id || memberId || res.data.id,
              UserType: "Guest",
            },
            { token: token.data.access_token },
            JSON.parse(store).email.toLowerCase()
          );
          if (crmSession.data && crmSession.data.access_token) {
            setFieldValue("session", crmSession.data.access_token);
            const ele = document.getElementById("submit");
            setTimeout(() => {
              setIsMemberChecked(false)
              ele?.click();
            }, 1000);
          } else {
            if (!isMemberChecked && memberId && res.data.id) {
              setIsMemberChecked(true);
              await getCustomerLookUpFunc(res.data.id);
            } else {
              errorToast(
                crmSession?.data?.error_description || "Unable to place request"
              );
            }
          }
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current.isBefore(moment().subtract(1, "day"));
  };

  const handleSaveNext = async () => {
    getCustomerLookUpFunc();
  };

  const formik = useFormik({
    initialValues: {
      destination: "",
      arrive: "",
      depart: "",
      guests: 1,
      roomCount: 1,
      promoCode: "",
      session: "",
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: findHotelValidation,
    onSubmit: handleSaveNext,
  });
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
    isValid,
  } = formik;

  // const onSearch = (value: string) => {
  //   console.log("search:", value);
  // };
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const handleHotelsListSuccess = (hotelList: any) => {
    return hotelList.map((hotel: any) => {
      return {
        value: hotel.code,
        label: hotel.name,
        name: hotel.name.split('My Place Hotel-')?.[1] || hotel.name.split('My Place Hotel_')?.[1] || hotel.name
      };
    }).sort((a: any, b: any) => a.name.localeCompare(b.name));
  };

  useEffect(() => {
    const getHotelsListFunc = async () => {
      const res: any = await getHotelsList();

      if (
        res &&
        res.data &&
        res.data.response &&
        res.data.response.status.success &&
        res.data.response.organization?.entities?.entity?.length
      ) {
        let hotels = res.data.response.organization.entities.entity?.filter(
          (val: any) => val.external_id_2 === "MPH"
        );
        setHotelsList(handleHotelsListSuccess(hotels));
      }
      return res;
    };

    getHotelsListFunc();
    return () => {};
  }, []);

  return (
    <form
      className="d-flex justify-center"
      id="sso-form"
      action={HOTEL_FIND_LINK}
      method="POST"
    >
      {/* <input onChange={() => {}} hidden name="chain" type="text" value="14161022" /> */}
      <input
        onChange={() => {}}
        hidden
        name="hotel"
        type="text"
        value={values.destination}
      />
      <input
        onChange={() => {}}
        hidden
        name="chain"
        type="text"
        value={CHAIN_ID}
      />
      <input
        onChange={() => {}}
        hidden
        name="Level"
        type="text"
        value="Chain"
      />
      <input
        onChange={() => {}}
        hidden
        name="adult"
        type="text"
        value={values.guests + ",0"}
      />
      <input
        onChange={() => {}}
        hidden
        name="arrive"
        type="text"
        value={values.arrive}
      />
      <input
        onChange={() => {}}
        hidden
        name="depart"
        type="text"
        value={values.depart}
      />
      <input
        onChange={() => {}}
        hidden
        name="rooms"
        type="text"
        value={values.roomCount}
      />
      <input
        onChange={() => {}}
        hidden
        name="code-type"
        type="text"
        value="promo"
      />
      <input
        onChange={() => {}}
        hidden
        name="promo"
        type="text"
        value={values.promoCode}
      />
      <input
        onChange={() => {}}
        hidden
        name="locale"
        type="text"
        value="en_US"
      />
      <input
        onChange={() => {}}
        hidden
        name="UserType"
        type="text"
        value="Guest"
      />
      <input
        onChange={() => {}}
        hidden
        name="session"
        type="text"
        value={values.session}
      />

      <div className="find-hotels d-flex flex-column">
        <h2 className="m-0 text-center fw-bold white">Book Today!</h2>
        <div className="destination d-flex flex-column">
          <div className="destination-text">
            <img src={locationPicker} alt="" />
            <p className="m-0">WHERE TO?</p>
          </div>
          <Select
            showSearch
            placeholder="Where to?"
            optionFilterProp="children"
            onChange={(text) => {
              handleChange("destination")(text);
              setTimeout(() => {
                formik.validateField("arrive");
                formik.validateField("depart");
                formik.validateField("destination");
              });
            }}
            style={{ width: "100%", height: "48px", fontSize: "18px" }}
            filterOption={filterOption}
            options={hotelsList}
          />
        </div>
        <div className="select-date d-flex flex-column">
          <div className="select-date-text">
            <img src={datePicker} alt="" />
            <p className="m-0">WHEN?</p>
          </div>
          <RangePicker
            className="range-picker"
            disabledDate={disabledDate}
            placeholder={["Check In", "Check Out"]}
            style={{ height: "48px" }}
            onChange={(text: any) => {
              setFieldValue("arrive", text?.[0]?.format("YYYY-MM-DD"));
              setFieldValue("depart", text?.[1]?.format("YYYY-MM-DD"));
              setTimeout(() => {
                formik.validateField("arrive");
                formik.validateField("depart");
                formik.validateField("destination");
              });
            }}
          />
        </div>
        <div className="guest-and-rooms d-flex">
          <MPHDropdown
            data={[1, 2, 3, 4, 5, 6]}
            labelField=""
            valueField=""
            labelClass={"white-label"}
            placeholder="Guests"
            label="GUESTS"
            value={values.guests}
            onChangeText={(text: any) => {
              handleChange("guests")(text);
            }}
            isError={touched.guests! && !!errors.guests}
            error={errors.guests}
          />
          <MPHDropdown
            data={[1, 2, 3, 4, 5]}
            labelField=""
            valueField=""
            placeholder="Rooms"
            label="ROOMS"
            labelClass={"white-label"}
            value={values.roomCount}
            onChangeText={(text: any) => {
              handleChange("roomCount")(text);
            }}
            isError={touched.roomCount! && !!errors.roomCount}
            error={errors.roomCount}
          />
          <MPHInput
            keyboardType="text"
            placeholder="Promo Code"
            labelClass={"white-label"}
            label="Promo Code"
            value={values.promoCode}
            onBlur={handleBlur("promoCode")}
            onChangeText={(text: any) => {
              handleChange("promoCode")(text);
            }}
          />
        </div>
        <div className="find-hotels-button-s">
          <MPHButton
            disabled={isLoading || !isValid}
            type={"submit"}
            buttonText={"Book Now"}
            isLoading={isLoading}
            onbuttonClick={() => handleSaveNext()}
          />
          <input
            onChange={() => {}}
            hidden
            id="submit"
            type="submit"
            value="Send"
          />
        </div>
      </div>
    </form>
  );
};

export default MPHFindHotels;
