import React, { useState } from "react";
import MPHInput from "src/components/DataProviders/MPHInput";
import MPHButton from "../MPHButtons/MPHButton";
import "./MPHSignUpProfileUpdate.scss";
import modalClose from "../../../assets/images/modalClose.png";
import {
  signUpProfileUpdateSchema,
  signUpUpdateValidation,
} from "src/utils/MPHFormsSchemaValidations/MPHFormsSchemaValidations";
import { errorToast, successToast } from "src/utils/toast";
import { useFormik } from "formik";
import { AUTH_ROUTES } from "src/config/constants";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import MPHRadiobutton from "src/components/DataProviders/MPHRadiobutton";
import { useNavigate,useParams,useLocation } from "react-router-dom";
import useAuth from "src/context/AuthContext";
import MPHCheckbox from "src/components/DataProviders/MPHCheckBox";

const MPHSignUpProfileUpdate = ({ validateToken , token, close, email }: any) => {
  const [initialValues] = useState(signUpProfileUpdateSchema);
  const { addCustomer,getCustomerByEmailLoggedIn } = useApiAxiosRequests(); //importing the getbyemail api
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setTokens, setUserProfile } = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const source = params.get('source')

  const handleSaveNext = async () => {
    if (!isValid) {
      Object.keys(initialValues).forEach((key: any) => {
        setFieldTouched(key);
      });
      return;
    }
    setIsLoading(true);
    let data: any = values;
    data["email"] = email.toLowerCase();

    //calling the getcustomerbyemail api with token received as prop and email to check if the user is already existing 
    const result:any = await getCustomerByEmailLoggedIn({email:data["email"]},validateToken);
    if(result.data.status.success === "true" && result.data.customers.customer[0].type === 'LOYALTY'){  
      //if user exist we get true in success field && user is loyal then we modify the current data to be sent to addCustomer api by removing extended fields that contains the member_type as loyalty_web.   
      let updatedInitialValues = {...initialValues}
      const {extended_fields, ...rest} = updatedInitialValues;
      data = rest; //assigned the updated values to data variable that we werea already using without extended fields into data 
    }else{
      const updatedvalue = {...values};

      if(source){
        const updatedFields = updatedvalue.extended_fields.field.map((item)=>{
          if(item.name === 'member_type'){
            return {...item,value:source};
          }
          return item;
        });

        //updating the value
        updatedvalue.extended_fields = {
          ...updatedvalue.extended_fields,
          field:updatedFields,
        };
      }

      data = updatedvalue;  //keeping the values intact
    }

    const res: any = await addCustomer(
      { root: { customer: [data] } },
      token.token,
      email.toLowerCase()
    );
    setIsLoading(false);
    if (res.data && res.data.status && res.data.status.success !== "false") {
      setTokens(token);
      const modal = document.getElementById("signUpDrop");
      const bm = document.getElementsByClassName("modal-backdrop");
      const mp = document.getElementsByClassName("modal-open");
      if (modal && modal.classList.contains("show")) {
        modal.classList.remove("show");
        modal.style.display = "none";
      }
      if (bm && bm[0] && bm[0].classList.contains("show")) {
        bm[0].classList.remove("show");
        bm[0].classList.remove("modal-backdrop");
      }
      if (mp && mp[0] && mp[0].classList.contains("modal-open")) {
        mp[0].classList.remove("modal-open");
      }
      setUserProfile({
        method: "auth",
        previousStep: "",
        token: token.token,
        role: "User",
        isProfileExist: true,
        links: AUTH_ROUTES,
        profileName: values.firstname,
        loyalty_points: 0,
        email: email.toLowerCase(),
      });
      navigate("/status");
      successToast(res.data.status.message);
    } else if (res.data) {
      if (
        res.data &&
        res.data.customers &&
        res.data.customers.customer &&
        res.data.customers.customer[0]
      ) {
        errorToast("Mobile Number already used");
        return;
      }

      errorToast(
        (res.data.status && res.data.status.message) ||
          "Unable to update please try again"
      );
      return;
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: signUpUpdateValidation,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleSaveNext,
  });
  const {
    values,
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    isValid,
  } = formik;

  return (
    <>
      <div className="modal-content form-modal-complete">
        <div className="row">
          <section className="mb-3">
            <h2 className="form-header">Complete Your Profile</h2>
            <img
              className="close-modal"
              src={modalClose}
              onClick={close}
              alt=""
            />
          </section>
          <div className="col-xl-8 col-lg-8 col-sm-12 col-md-12 mt-2 mb-3">
            <label htmlFor="" className="form-label">
              First Name
            </label>
            <MPHInput
              keyboardType="text"
              value={values.firstname}
              onBlur={handleBlur("firstname")}
              onChangeText={(text: any) => {
                handleChange("firstname")(text);
              }}
              isError={touched.firstname! && !!errors.firstname}
              error={errors.firstname}
              isModal={true}
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12 col-md-12 mt-2 mb-3">
            <label htmlFor="" className="form-label">
              M/I (optional)
            </label>
            <MPHInput
              keyboardType="text"
              value={values.custom_fields.field[0].value}
              onBlur={handleBlur("custom_fields.field[0].value")}
              onChangeText={(text: any) => {
                handleChange("custom_fields.field[0].value")(text);
              }}
              isError={
                touched.custom_fields &&
                touched.custom_fields.field &&
                errors.custom_fields &&
                errors.custom_fields.field &&
                errors.custom_fields.field.length &&
                errors.custom_fields.field[0] &&
                touched.custom_fields.field[0].value! &&
                !!errors.custom_fields.field.length
              }
              error={
                errors.custom_fields &&
                errors.custom_fields.field &&
                errors.custom_fields.field.length &&
                "Middle/Initial Is Required"
              }
              isModal={true}
            />
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-2 mb-3">
            <label htmlFor="" className="form-label">
              Last Name
            </label>
            <MPHInput
              keyboardType="text"
              value={values.lastname}
              onBlur={handleBlur("lastname")}
              onChangeText={(text: any) => {
                handleChange("lastname")(text);
              }}
              isError={touched.lastname! && !!errors.lastname}
              error={errors.lastname}
              isModal={true}
            />
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-2 mb-3">
            <label htmlFor="" className="form-label">
              Phone
            </label>
            <MPHInput
              keyboardType="number"
              value={values.mobile}
              max={10}
              onBlur={handleBlur("mobile")}
              onChangeText={(text: any) => {
                handleChange("mobile")(text);
              }}
              isError={touched.mobile! && !!errors.mobile}
              error={errors.mobile}
              isModal={true}
            />
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-2 mb-3">
            <label htmlFor="" className="form-label">
              Type Of Phone
            </label>
            <div className="radio-options">
              <MPHRadiobutton
                isModal={true}
                label="Mobile"
                valueField={"Mobile"}
                inputFields={{
                  name: "phone",
                }}
                value={values.custom_fields.field[1].value}
                onBlur={handleBlur("custom_fields.field[1].value")}
                onChangeText={(text: any) => {
                  handleChange("custom_fields.field[1].value")(text);
                }}
              />
              <MPHRadiobutton
                isModal={true}
                label="Business"
                valueField={"Business"}
                inputFields={{
                  name: "phone",
                }}
                value={values.custom_fields.field[1].value}
                onBlur={handleBlur("custom_fields.field[1].value")}
                onChangeText={(text: any) => {
                  handleChange("custom_fields.field[1].value")(text);
                }}
              />
              <MPHRadiobutton
                isModal={true}
                label="Home"
                valueField={"Home"}
                inputFields={{
                  name: "phone",
                }}
                value={values.custom_fields.field[1].value}
                onBlur={handleBlur("custom_fields.field[1].value")}
                onChangeText={(text: any) => {
                  console.log(text);

                  handleChange("custom_fields.field[1].value")(text);
                }}
              />
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-1 mb-3">
            <MPHCheckbox
              label={
                "I would like to receive personalized emails, including exclusive offers and promotions, from My Place Hotels of America."
              }
              key={"email"}
              id={"email"}
              isModal={true}
              value={
                values.subscriptions.subscription[0].is_subscribed === "0"
                  ? false
                  : true
              }
              onChangeText={(text: any) => {
                setFieldValue(
                  "subscriptions.subscription[0].is_subscribed",
                  text === true ? "1" : "0"
                );
              }}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 mt-5">
            <MPHButton
              buttonText="Submit"
              disabled={isLoading || !isValid}
              isLoading={isLoading}
              onbuttonClick={() => handleSaveNext()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MPHSignUpProfileUpdate;
