import { useNavigate } from "react-router-dom";
import { APP_URLS } from "src/config/api-urls";
import { BRAND_NAME, CAP_API, CRM_API, DEVICE_ID } from "src/config/constants";
import useAuth from "src/context/AuthContext";
import { isSessionValid } from "../session";
import { useStorage } from "../storage";
import { useAxios } from "../transport/useAxios";

export function useApiAxiosRequests() {
  const axios = useAxios();
  const { setTokens, logout, setUserProfile, clearStorage } = useAuth();
  const navigate = useNavigate();
  const storage = useStorage();
  const handleSessionAndUnathorized = async (message: any) => {
    if (
      (message?.Message || message?.message) === "Session expired" ||
      (message?.Message || message?.message) === "Unathorized User"
    ) {
      await logout();
      clearStorage();
      navigate("");
      await setUserProfile(null);
    } else {
    }
  };

  const checkValidSession = async () => {
    const token: any = await storage.get("app_tokens");
    if (token) {
      const authToken = JSON.parse(token);
      if (authToken && authToken.token) {
        if (!isSessionValid(authToken.token)) {
          setUserProfile(null);
          clearStorage();
          navigate("");
        }
      } else {
        handleSessionAndUnathorized("Unathorised User");
      }
    } else {
      handleSessionAndUnathorized("Unathorised User");
    }
  };

  const forgotPassword = async (body: any) => {
    try {
      let res = await axios.post(APP_URLS.ForgotPassword(), body);
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const passwordChange = async (body: any) => {
    try {
      let res = await axios.post(APP_URLS.PasswordChange(), body);
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const forGotPasswordChange = async (body: any) => {
    try {
      let res = await axios.post(APP_URLS.ForgotPassword(), body);
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const passwordValidate = async (body: any) => {
    try {
      let res = await axios.post(APP_URLS.PasswordValidate(), body);
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };
  const otpValidate = async (body: any) => {
    try {
      let res = await axios.post(APP_URLS.OtpValidate(), body);
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };
  const otpGenerate = async (body: any) => {
    try {
      let res = await axios.post(APP_URLS.OtpGenerate(), body);
      console.log(res);

      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const tokenReGenearte = async (body: any) => {
    try {
      let res = await axios.post(APP_URLS.tokenGenerate(), body);
      if (res && res.data && res.data.status && res.data.status.success) {
        setTokens(res.data.auth);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const tokenGenerate = async (body: any) => {
    try {
      let res = await axios.post(APP_URLS.tokenGenerate(), body);
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getLocations = async (params?: any) => {
    try {
      let res = await axios.get(APP_URLS.ForgotPassword(), { params: params });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getOffers = async (params?: any) => {
    try {
      let res = await axios.get(APP_URLS.ForgotPassword(), { params: params });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getStatus = async (params?: any) => {
    await checkValidSession();
    try {
      let res = await axios.get(APP_URLS.ForgotPassword(), { params: params });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getEarnPoints = async (params?: any) => {
    await checkValidSession();
    try {
      let res = await axios.get(APP_URLS.ForgotPassword(), { params: params });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getRedeemPoints = async (params?: any) => {
    await checkValidSession();
    try {
      let res = await axios.get(APP_URLS.ForgotPassword(), { params: params });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getBenfits = async (params?: any) => {
    await checkValidSession();
    try {
      let res = await axios.get(APP_URLS.ForgotPassword(), { params: params });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const corporateGroupsRequest = async (body: any) => {
    try {
      let res = await axios.post(APP_URLS.ForgotPassword(), body);
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const contactUs = async (body: any, email?: any, token?: any) => {
    await checkValidSession();
    try {
      let res = await axios.post(APP_URLS.ContactUs(), body, {
        baseURL: CAP_API,
        headers: {
          cap_authorization: token,
          cap_brand: BRAND_NAME,
          cap_identifier_value: email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getCustomerByEmail = async (params: any, token?: any) => {
    try {
      let res = await axios.get(APP_URLS.GetCustomerByEMail(), {
        params: params,
        baseURL: CAP_API,
        headers: {
          cap_authorization: token,
          cap_brand: BRAND_NAME,
          cap_identifier_value: params.email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getCustomerByEmailLoggedIn = async (params: any, token?: any) => {
    await checkValidSession();
    try {
      let res = await axios.get(APP_URLS.GetCustomerByEMailLoggedIn(), {
        params: params,
        baseURL: CAP_API,
        headers: {
          cap_authorization: token,
          cap_brand: BRAND_NAME,
          cap_identifier_value: params.email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getCustomerLookUp = async (params: any, token?: any) => {
    await checkValidSession();
    try {
      let res = await axios.get(APP_URLS.LookUp(), {
        params: params,
        baseURL: CAP_API,
        headers: {
          cap_authorization: token,
          cap_brand: BRAND_NAME,
          cap_identifier_value: params.identifierValue,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getCustomerLookUpAuthToken = async (payload: any, auth: any, email?: any) => {
    await checkValidSession();
    try {
      let res = await axios.post(APP_URLS.LookUpAuthToken(auth), payload, {
        baseURL: CAP_API,
        headers: {
          cap_brand: BRAND_NAME,
          cap_identifier_value: email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getCustomerResevations = async (params: any, email?: any) => {
    await checkValidSession();
    try {
      let res = await axios.get(APP_URLS.Reservations(), {
        baseURL: CAP_API,
        params: params,
        headers: {
          cap_brand: BRAND_NAME,
          cap_identifier_value: email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getCrmData = async (type: any) => {
    try {
      let res = await axios.get(APP_URLS.CrmData(type), {
        baseURL: CRM_API,
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  };

  const getCRMSession = async (body: any, params: any, email?: any) => {
    await checkValidSession();
    try {
      let res = await axios.post(APP_URLS.CRMSession(), body, {
        baseURL: CAP_API,
        params: params,
        headers: {
          cap_brand: BRAND_NAME,
          cap_identifier_value: email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getCustomerv1 = async (params: any, token?: any) => {
    try {
      let res = await axios.get(APP_URLS.GetCustomerV1(), {
        params: params,
        baseURL: CAP_API,
        headers: { isCap: true, token: token },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const addCustomer = async (paylaod: any, token?: any, email?: any) => {
    try {
      let res = await axios.post(APP_URLS.AddCustomer(), paylaod, {
        baseURL: CAP_API,
        headers: {
          cap_authorization: token,
          cap_brand: BRAND_NAME,
          cap_identifier_value: email || paylaod.root.customer[0].email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const isRedeemable = async (params: any) => {
    await checkValidSession();
    try {
      let res = await axios.get(APP_URLS.IsRedeemable(), {
        baseURL: CAP_API,
        params,
        headers: {
          cap_brand: BRAND_NAME,
          cap_identifier_value: params.email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const redeemPoints = async (paylaod: any, email?: any) => {
    await checkValidSession();
    try {
      let res = await axios.post(APP_URLS.RedeemPoints(), paylaod, {
        baseURL: CAP_API,
        headers: {
          cap_brand: BRAND_NAME,
          cap_identifier_value: paylaod.email || email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const updateCustomer = async (paylaod: any, token?: any, email?: any) => {
    await checkValidSession();
    try {
      let res = await axios.post(APP_URLS.UpdateCustomer(), paylaod, {
        baseURL: CAP_API,
        headers: {
          cap_authorization: token,
          cap_brand: BRAND_NAME,
          cap_identifier_value:
            email || paylaod.root.customer[0].email || email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getCustomerTransaction = async (params: any, token?: any) => {
    await checkValidSession();
    try {
      let res = await axios.get(APP_URLS.GetCustomerTransactionV1(), {
        params: params,
        baseURL: CAP_API,
        headers: {
          cap_authorization: token,
          cap_brand: BRAND_NAME,
          cap_identifier_value: params.email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getCustomerRewards = async (params: any, token?: any) => {
    await checkValidSession();
    try {
      let res = await axios.get(APP_URLS.GetCustomerRewards(), {
        params: params,
        baseURL: CAP_API,
        headers: {
          cap_authorization: token,
          cap_brand: BRAND_NAME,
          cap_identifier_value: params.email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getSubscription = async (params: any, token?: any) => {
    await checkValidSession();
    try {
      let res = await axios.get(APP_URLS.GetSubscriptionV2(), {
        params: params,
        baseURL: CAP_API,
        headers: {
          cap_authorization: token,
          cap_brand: BRAND_NAME,
          cap_identifier_value: params.email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const changeEmail = async (body: any, email?: any, token?: any) => {
    await checkValidSession();
    try {
      let res = await axios.post(APP_URLS.ChangeEmail_Mobile(), body, {
        baseURL: CAP_API,
        headers: {
          cap_authorization: token,
          cap_brand: BRAND_NAME,
          cap_identifier_value: email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const changeMobile = async (body: any, email?: any, token?: any) => {
    await checkValidSession();
    try {
      let res = await axios.post(APP_URLS.ChangeEmail_Mobile(), body, {
        baseURL: CAP_API,
        headers: {
          cap_authorization: token,
          cap_brand: BRAND_NAME,
          cap_identifier_value: email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const getCustomerLedger = async (params: any, token?: any) => {
    try {
      let res = await axios.get(APP_URLS.GetCustomerLedgerV2(), {
        params: params,
        baseURL: CAP_API,
        headers: { isCap: true, token: token },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };
  const getHotelsList = async () => {
    let payload: any;
    const store: any = storage.get("user_profile");
    if (store && JSON.parse(store) && JSON.parse(store).email) {
      payload = {
        email: JSON.parse(store).email,
      };
    }
    const params: any = {
      type: "STORE",
      format: "json",
      email: payload && payload.email,
    };
    try {
      let res = await axios.get(APP_URLS.GetHotelsListV2(), {
        params: params,
        baseURL: CAP_API,
        headers: {
          cap_brand: BRAND_NAME,
          cap_identifier_value: payload.email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("GetHotelList Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  const emailSubscription = async (email: string) => {
    let payload: any;
    const store: any = storage.get("user_profile");
    if (store && JSON.parse(store).email) {
      payload = {
        email: JSON.parse(store).email,
      };
    }
    const body: any = {
      root: {
        customer: [
          {
            email,
            subscriptions: {
              subscription: [
                {
                  priority: "BULK",
                  scope: "all",
                  is_subscribed: "1",
                  channel: "email",
                },
              ],
            },
          },
        ],
      },
    };
    try {
      let res = await axios.post(APP_URLS.EmailSubscriptionV2(), body, {
        baseURL: CAP_API,
        headers: {
          cap_brand: BRAND_NAME,
          cap_identifier_value: payload.email,
          cap_identifier_type: "EMAIL",
          cap_device_id: DEVICE_ID,
        },
      });
      if (res && res.data && res.data.status && res.data.status.success) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log("LoginRegister Api err", error);
      handleSessionAndUnathorized(error);
      return error;
    }
  };

  return {
    forgotPassword,
    tokenGenerate,
    tokenReGenearte,
    otpGenerate,
    otpValidate,
    passwordValidate,
    passwordChange,
    forGotPasswordChange,
    getOffers,
    getLocations,
    getStatus,
    getRedeemPoints,
    getBenfits,
    getEarnPoints,
    corporateGroupsRequest,
    contactUs,
    getCustomerv1,
    getCustomerByEmail,
    getCustomerByEmailLoggedIn,
    addCustomer,
    updateCustomer,
    getCustomerTransaction,
    getCustomerLedger,
    getHotelsList,
    emailSubscription,
    changeEmail,
    changeMobile,
    getCustomerLookUp,
    getCustomerLookUpAuthToken,
    getCustomerResevations,
    getCRMSession,
    getCrmData,
    getSubscription,
    isRedeemable,
    redeemPoints,
    getCustomerRewards,
  };
}
