import React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";
import Contact from "src/screens/Contact/Contact";
import EarnPoints from "src/screens/EarnPoints/EarnPoints";
import HomePage from "src/screens/HomePage/HomePage";
import MyProfile from "src/screens/MyProfile/MyProfile";
import MyStays from "src/screens/MyStays/MyStays";
import RedeemPoints from "src/screens/RedeemPoints/RedeemPoints";
import MyStatus from "src/screens/MyStatus/MyStatus";
import MyBenefits from "src/screens/MyBenefits/MyBenefits";
import MyRewards from "src/screens/MyRewards/MyRewards";

const NavigationStack = ({ loggedIn }: any) => {
  return (
    <>
        <Routes>
          <Route path="/" element={<HomePage type={"login"} />} />
          <Route path="/login" element={<HomePage type={"login"} />} />
          <Route path="/signup" element={<HomePage type={"signup"} />} />
          <Route
            path="/create-password"
            element={<HomePage type={"create-password"} />}
          />
          <Route
            path="/forgot-password"
            element={<HomePage type={"forgot-password"} />}
          />
          <Route
            path="/find-hotels"
            element={<HomePage type={"find-hotels"} />}
          />
          {loggedIn && (
            <>
              <Route path="/earn" Component={EarnPoints} />
              <Route path="/redeem" Component={RedeemPoints} />
              <Route path="/stays" Component={MyStays} />
              <Route path="/status" Component={MyStatus} />
              <Route path="/benefits" Component={MyBenefits} />
              <Route path="/profile" Component={MyProfile} />
              <Route path="/contact" Component={Contact} />
              <Route path="/rewards" Component={MyRewards} />
            </>
          )}
          {/* <Route path="/offers" Component={FeaturedOffers} /> */}
          {/* <Route path="/locations" Component={FeaturedLocation} /> */}
          {/* <Route path="/corporate" Component={CorporateGroups} /> */}
          <Route path="*" Component={HomePage} />
        </Routes>
    </>
  );
};

export default NavigationStack;
