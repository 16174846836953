import React, { useState } from "react";
import MPHButton from "../MPHButtons/MPHButton";
import "./MPHOtp.scss";
import shield from "../../../assets/images/shield.png";
import modalClose from "../../../assets/images/modal-close-black.png";
import modalCloseWhite from "../../../assets/images/modalClose.png";
import OtpInput from "react-otp-input";

const MPHOtp = ({
  text,
  onOtp,
  isLoading,
  isModal = true,
  length = 6,
  onClose,
  darkTheme = false,
  resend,
}: any) => {
  const [otp, setOtp] = useState("");
  return (
    <>
      <div
        className={
          (darkTheme ? "form-modal-otp-dark" : "form-modal-otp") +
          " modal-content"
        }
      >
        {isModal ? (
          <img
            className="close-modal"
            src={darkTheme ? modalCloseWhite : modalClose}
            onClick={() => onClose()}
            alt=""
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        ) : (
          <img
            className="close-modal"
            src={darkTheme ? modalCloseWhite : modalClose}
            onClick={() => onClose()}
            alt=""
          />
        )}
        <div className="row">
          <section className="mb-3 mt-3">
            <h2 className="form-header-otp">Verify Your Account</h2>
            <p className="form-sub-header-otp pt-2">
              Please check your email for a verification code. Please do not exit this page without entering your verification code.
            </p>
          </section>
          <div className="col-12 mt-1">
            <h2 className="form-label-otp">Enter verification code</h2>
            <OtpInput
              containerStyle="style-otp"
              inputType={"number"}
              value={otp}
              onChange={(otp: any) => {
                setOtp(otp);
              }}
              numInputs={length}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <div className="col-12 mt-4">
            <h2 className="helper">
              Note: If you did not receive a verification code, check your spam
              or junk folders. <br />
              {/* <ul>
                <li>Check your spam or junk email folder</li>
                <li>Confirm that your email address was entered correctly.</li>
              </ul> */}
            </h2>
          </div>
          {/* <div className="col-12">
            <h3 className="resend-code mt-1" onClick={resend}>
              Resend Code
            </h3>
          </div> */}
          <div className="col-12 mt-4">
            <MPHButton
              buttonText="Submit"
              buttonType={darkTheme ? "primary-button" : "secondary-button"}
              isLoading={isLoading}
              onbuttonClick={() => onOtp(otp)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MPHOtp;
