import React from "react";
import "./Common.scss";

type DropDownProps = {
  labelField: string;
  valueField: string;
  data: Array<any>;
  disabled?: boolean;
  optiondisabled?: boolean;
  label?: string;
  value?: any;
  onChangeText: (t: string, v?: { [key: string]: string }) => void;
  placeholder?: string;
  isError?: any;
  error?: any;
  registerInput?: any;
  normalLabel?: boolean;
  labelClass?: any;
  isModal?: any;
};

const MPHDropdown = ({
  labelField,
  valueField,
  placeholder,
  label,
  data,
  onChangeText,
  value,
  labelClass,
  disabled = false,
  isError,
  error,
  registerInput = {},
  normalLabel = false,
  optiondisabled = true,
  isModal,
}: DropDownProps) => {
  return (
    <>
      {isModal ? (
        <section>
          <section className="i-icon">
            <select
              disabled={disabled}
              value={value}
              name=""
              id={registerInput ? { ...registerInput }.name : ""}
              className="modal-input"
              onChange={(txt: any) => onChangeText(txt)}
              {...registerInput}
            >
              <option value={""} disabled={optiondisabled}>
                {placeholder}
              </option>
              {data &&
                data.length &&
                data.map((val: any) => (
                  <option
                    className="options"
                    key={(val[valueField] || val) + (val[labelField] || val)}
                    value={val[valueField] || val}
                  >
                    {val[labelField] || val}
                  </option>
                ))}
            </select>
          </section>
          {isError && (
            <p className="error-code text-decoration-none">{error}</p>
          )}
        </section>
      ) : (
        <>
          <section className="mb-3">
            {label && (
              <label
                className={
                  labelClass || (normalLabel ? "normal-label" : "form-label")
                }
              >
                {label}
              </label>
            )}
            <select
              disabled={disabled}
              value={value}
              name=""
              id={registerInput ? { ...registerInput }.name : ""}
              className="form-input"
              onChange={(txt: any) => onChangeText(txt)}
              {...registerInput}
            >
              <option value={""} disabled={optiondisabled}>
                {placeholder}
              </option>
              {data &&
                data.length &&
                data.map((val: any) => (
                  <option
                    className="options"
                    key={(val[valueField] || val) + (val[labelField] || val)}
                    value={val[valueField] || val}
                  >
                    {val[labelField] || val[valueField] || val}
                  </option>
                ))}
            </select>
            {isError && (
              <p className="error-code text-decoration-none">{error}</p>
            )}
          </section>
        </>
      )}
    </>
  );
};

export default MPHDropdown;
