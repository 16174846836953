import React from "react";

type RadioButtonProps = {
  disabled?: boolean;
  label?: string;
  value?: any;
  valueField?: any;
  normalLabel?: boolean;
  inputFields?: any;
  onChangeText?: any;
  isModal?: any;
  onBlur?: (e: any) => void;
};

const MPHRadiobutton = ({
  label,
  value,
  disabled = false,
  normalLabel = false,
  onBlur,
  isModal,
  valueField,
  onChangeText,
  inputFields = {},
}: RadioButtonProps) => {
  return (
    <>
      <div className="radio-group d-flex align-items-center">
        <input
          {...inputFields}
          type="radio"
          value={valueField}
          disabled={disabled}
          onBlur={onBlur}
          onChange={($event: any) => onChangeText($event?.target?.value)}
          checked={valueField === value}
        />
        <label className={isModal ? "modal-radio" : "radio-label"}> {label}</label>
      </div>
    </>
  );
};

export default MPHRadiobutton;
