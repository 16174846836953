import Axios, { AxiosError, AxiosInstance } from "axios";
import React, { createContext, useMemo, useRef } from "react";
import { AuthProvider } from "src/context/AuthContext";
import MainNav from "src/navigation/MainNav";
import { API_URL, CRM_API } from "src/config/constants";
import { useStorage } from "src/utils/storage";

export const baseAxios = Axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const AxiosContext = createContext<AxiosInstance>(baseAxios);

export function AxiosProvider() {
  const ref = useRef<any>();
  const storage: any = useStorage();

  const axios = useMemo(() => {
    baseAxios.interceptors.request.use(async (config) => {
      const _tokens = storage.get("app_tokens") as any;
      const parseToken = (_tokens && JSON.parse(_tokens)) || "";
      if (!config?.headers?.cap_brand && config.baseURL !== CRM_API) {
        config.headers = {
          ...config.headers,
          ...(parseToken &&
            parseToken.token && {
              authorization: `Bearer ${parseToken.token}`,
            }),
        };
      }

      if (config?.headers?.cap_brand) {
        config.headers = {
          ...config.headers,
          ...(parseToken &&
            parseToken.token && {
              "cap_authorization":
                config.headers.cap_authorization || parseToken.token,
            }),
        };
      }

      return config;
    });

    baseAxios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: Error | AxiosError) => {
        console.log("error", error, error.message);
        if (Axios.isAxiosError(error)) {
          const { response: { status, statusText, config, data } = {} } = error;
          if (status === 401) {
            ref?.current?.logout();
            return Promise.reject(new Error("Session expired"));
          }
          if (status === 403 && config?.headers?.isTP) {
            ref?.current?.logout();
            return Promise.reject(new Error("Unathorized User"));
          }
          if (typeof data === "string") {
            return Promise.reject(new Error(data));
          } else {
            const errorText =
              (data as any)?.Message ||
              (data as any)?.message ||
              statusText ||
              error.message ||
              data ||
              "INTERNAL SERVER ERROR";
            return Promise.reject(new Error(errorText));
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
    return baseAxios;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AxiosContext.Provider value={axios}>
        {/* @ts-ignore */}
        <AuthProvider ref={ref}>
          <MainNav />
        </AuthProvider>
      </AxiosContext.Provider>
    </>
  );
}
