import { createSlice } from '@reduxjs/toolkit';
import { OPEN_ROUTES } from 'src/config/constants';
type Method = 'signup' | 'forgot' | 'login';

export type Roles =
  | 'User'
  | 'Admin'

type Authentication = {
  role: Roles;
  method: Method;
  previousStep: string;
  token: string;
  isProfileExist: boolean;
  links: any;
  profileName: any;
  loyalty_points: any;
  email: any;
};

const initialState: Authentication = {
  method: 'signup',
  previousStep: '',
  token: '',
  role: 'User',
  isProfileExist: true,
  links: OPEN_ROUTES,
  profileName: '',
  loyalty_points: 0,
  email: ''

};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setAuthenticationToken: (state, action) => {
      state.token = action.payload;
    },
    resetJourney: () => initialState,
    setProfile: (state, { payload }) => {
      state = payload;
    },
  },
});

export default authSlice.reducer;
export const {
  resetJourney,
  setProfile,
} = authSlice.actions;
