import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { useStorage } from "src/utils/storage";
import { useDispatch } from "react-redux";
import { isSessionValid } from "src/utils/session";
import { resetJourney, setProfile } from "src/store/features/authSlice";
import { OPEN_ROUTES } from "src/config/constants";

interface AuthContextType {
  isAuthenticated: boolean;
  isTokenLoading: boolean;
  setUserProfile: (user: any) => void;
  clearStorage: () => void;
  tokens?: any;
  setTokens: (token: any) => void;
  logout: () => void;
  user?: any;
}
export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const AuthProvider = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const storage = useStorage();
  const defaultUser = {
    method: "signup",
    previousStep: "",
    token: "",
    role: "User",
    isProfileExist: false,
    links: OPEN_ROUTES,
    profileName: "",
    loyalty_points: 0,
    email: "",
  };
  const [user, setUser] = useState<any>({
    method: "signup",
    previousStep: "",
    token: "",
    role: "User",
    isProfileExist: false,
    links: OPEN_ROUTES,
    profileName: "",
    loyalty_points: 0,
    email: "",
  });
  const [isTokenLoading, setIsTokenLoading] = useState<boolean>(false);
  const [tokens, setAccessTokens] = useState<any>({});
  const isAuthenticated = tokens.accessToken !== "";

  const setUserProfile = (data: any) => {
    storage.set("user_profile", JSON.stringify(data || defaultUser));
    setUser(data || defaultUser);
    dispatch(setProfile(data || defaultUser));
  };

  const setTokens = (token: any) => {
    storage.set("app_tokens", JSON.stringify(token));
    setAccessTokens(token);
  };

  const delay = (ms: number) => {
    return new Promise((resolve: any) => setTimeout(resolve, ms));
  };
  const logout = async () => {
    await delay(500);
    await storage.clear();
    setUserProfile(null);
    setAccessTokens({});
    dispatch(resetJourney());
  };

  const clearStorage = async () => {
    await storage.clear();
  };

  useImperativeHandle(ref, () => ({
    async logout() {
      await logout();
    },
  }));

  const isLoggedIn = async () => {
    // setIsTokenLoading(true);
    // const _userToken = await storage.get("app_tokens");
    // let hasValidProfile: boolean = false;
    // console.log('here');
    // try {
    //   if (_userToken && isSessionValid(_userToken)) {
    //     hasValidProfile = true;
    //     const _userProfile = await storage.get("user_profile");
    //     _userToken && setAccessTokens(JSON.parse(_userToken));
    //     _userProfile && setUser(JSON.parse(_userProfile));
    //   }
    //   if (!hasValidProfile) {
    //     logout();
    //   }
    // } catch (e) {
    //   logout();
    // } finally {
    //   setIsTokenLoading(false);
    // }
  };

  useEffect(() => {
    if (tokens.accessToken === "") {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens.accessToken]);

  useEffect(() => {
    isLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        setTokens,
        logout,
        clearStorage,
        user,
        isTokenLoading,
        tokens,
        setUserProfile,
        isAuthenticated,
      }}
    >
      {(props as any).children}
    </AuthContext.Provider>
  );
});
export default function useAuth() {
  return useContext(AuthContext);
}
