/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Helmet } from 'react-helmet';
import MPHFooterPrimary from "src/components/actionPerformers/MPHFooterPrimary/MPHFooterPrimary";
import MPHFooterSecondary from "src/components/actionPerformers/MPHFooterSecondary/MPHFooterSecondary";
import MPHHeaderMain from "src/components/actionPerformers/MPHHeaderMain/MPHHeaderMain";
import "./MyStatus.scss";
import MPHStatusComponent from "src/components/actionPerformers/MPHStatusComponent/MPHStatusComponent";

const MyStatus = () => {
  return (
    <>
      <Helmet>
        <title>Check Your Stay Rewarded Status - My Place Hotels
        </title>
        <meta name="description" content="View your Stay Rewarded account status and track your points balance. Stay up-to-date with your membership at My Place Hotels." />
      </Helmet>
      <MPHHeaderMain active="/status" />
      <div className="margin-top">
        <div className="my-status">
          <MPHStatusComponent />
        </div>
        <MPHFooterPrimary />
        <MPHFooterSecondary />
      </div>
    </>
  );
};

export default MyStatus;
