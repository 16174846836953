import React from "react";
import btnIcon from "../../../assets/images/buttonTriangle.png";
import btnIconYellow from "../../../assets/images/buttonTriangleYellow.png";
import btnIconYellowRight from "../../../assets/images/buttonTriangleYellowRight.png";
import "./MPHButton.scss";
import { Circles } from "react-loader-spinner";

const MPHButton = ({
  buttonText,
  onbuttonClick,
  isLoading,
  loaderColor = "black",
  buttonType,
  disabled,
  type = "",
  backward = false,
}: any) => {
  return (
    <>
      <button
        disabled={disabled}
        className={
          (buttonType ? buttonType : "primary-button") +
          " d-flex form-button" +
          (disabled ? " disabled" : "")
        }
        type={type}
        onClick={onbuttonClick}
      >
        {isLoading && (
          <Circles
            height="10"
            width="10"
            color={loaderColor}
            ariaLabel="loading"
          />
        )}
        {backward && (
          <img
            className="mt-0"
            src={
              buttonType === "secondary-button" ? btnIconYellowRight : btnIcon
            }
            alt=""
          />
        )}
        <span>{buttonText}</span>
        {!backward && (
          <img
            className="mt-0"
            src={buttonType === "secondary-button" ? btnIconYellow : btnIcon}
            alt=""
          />
        )}
      </button>
    </>
  );
};

export default MPHButton;
