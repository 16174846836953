import React from "react";
import MPHButton from "../../actionPerformers/MPHButtons/MPHButton";
import MPHFindHotels from "src/components/actionPerformers/MPHFindHotels/MPHFindHotels";
import "./MPHBanner.scss";
import useAuth from "src/context/AuthContext";
const MPHBanner = ({
  url,
  header,
  helperText,
  buttonText,
  isFind,
  onbuttonClick,
}: any) => {
  const { user } = useAuth();

  return (
    <div
      style={{
        backgroundImage: `url(${url})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "70vh",
      }}
    >
      <section className="banner-content">
        {user && user.isProfileExist && !isFind ? (
          <MPHFindHotels />
        ) : (
          <>
            <div className="banner-text d-flex flex-column">
              {header ? (
                <h1 className="banner-header m-0">
                  {header === "Where will you go next?" ? (
                    <>
                      Where will you <br /> go next?
                    </>
                  ) : (
                    header
                  )}
                </h1>
              ) : (
                ""
              )}
              {helperText ? (
                <p className="banner-helper">{helperText}</p>
              ) : (
                <p className="banner-helper">
                  First time signing in?{" "}
                  <span data-bs-toggle="modal" data-bs-target="#signUpDrop">
                    Create your password
                  </span>
                  .
                </p>
              )}
            {buttonText ? (
              <MPHButton
                buttonText={buttonText}
                onbuttonClick={onbuttonClick}
              />
            ) : (
              ""
            )}
            </div>
          </>
        )}
      </section>
    </div>
  );
};

export default MPHBanner;
