import jwt_decode from 'jwt-decode';

export function isSessionValid(token: string) {
  const decode: any = jwt_decode(token);
  return Math.floor(Date.now() / 1000) < decode.exp;
}


export function getEmail(storage: any) {
  const store: any = storage.get("user_profile");
  if (store && JSON.parse(store) && JSON.parse(store).email) {
    return JSON.parse(store).email.toLowerCase();
  } else {
    return '';
  }

}
