import React, { useState } from "react";
import MPHInput from "src/components/DataProviders/MPHInput";
import MPHButton from "../MPHButtons/MPHButton";
import "./MPHSignUp.scss";
import modalClose from "../../../assets/images/modalClose.png";
import {
  signUpSchema,
  signUpValidation,
} from "src/utils/MPHFormsSchemaValidations/MPHFormsSchemaValidations";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import { errorToast, successToast } from "src/utils/toast";
import { useFormik } from "formik";
import MPHOtp from "../MPHOtp/MPHOtp";
import MPHSignUpProfileUpdate from "../MPHSignUpProfileUpdate/MPHSignUpProfileUpdate";

const MPHSignUp = () => {
  const [isOtp, setIsOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileUpdate, setIsProfileUpdate]: any = useState(null);
  const [token, settoken] = useState("");
  const [validateToken,setvalidateToken]= useState("");
  const [sessionId, setSessionId] = useState("");
  const [initialValues] = useState(signUpSchema);
  const { tokenGenerate, otpGenerate, otpValidate } = useApiAxiosRequests();

  const tokenGenerateFunc = async () => {
    const payload = values;
    payload.identifierValue = payload.identifierValue.toLowerCase();
    const res: any = await tokenGenerate(payload);
    if (res.data && res.data.status && res.data.status.success) {
      settoken(res.data.auth.token);
      setSessionId(res.data.user.sessionId);
    } else {
      if (res.data && res.data.status && res.data.status.message) {
        errorToast(res.data && res.data.status && res.data.status.message);
      }
    }
    return {
      status: res.data && res.data.status && res.data.status.success,
      data: res.data,
    };
  };

  const handleSaveNext = async () => {
    setIsLoading(true);
    const userDetails = await tokenGenerateFunc();
    if (!userDetails.status) {
      setIsLoading(false);
      return;
    }
    if (userDetails.data.user.userRegisteredForPassword) {
      errorToast("Duplicate Email, please login or try with another Email");
      setIsLoading(false);
      return;
    }
    if (!userDetails.data.user.userRegisteredForPassword) {
      generateOtpFunc(userDetails.data.user);
      return;
    }
  };

  const generateOtpFunc = async (data: any) => {
    const payload = {
      identifierType: values.identifierType,
      identifierValue: values.identifierValue.toLowerCase(),
      deviceId: values.deviceId,
      brand: values.brand,
      sessionId: data.sessionId,
    };
    const res: any = await otpGenerate(payload);
    setIsLoading(false);

    if (res && res.data && res.data.status && res.data.status.success) {
      successToast(res.data.status.message);
      setIsOtp(true);
    } else {
      errorToast(
        (res && res.data && res.data.status && res.data.status.message) ||
          "Error Generating in verification code, Please try again"
      );
    }
  };

  const otpValidateFunc = async (otp: any) => {
    const payload = {
      identifierType: values.identifierType,
      identifierValue: values.identifierValue.toLowerCase(),
      deviceId: values.deviceId,
      brand: values.brand,
      sessionId: sessionId,
      otp: otp,
    };
    setIsLoading(true);
    const res: any = await otpValidate(payload);
    setIsLoading(false);
    if (res.data && res.data.status && res.data.status.success) {
      if (res.data.auth && res.data.auth.token) {
        setvalidateToken(res.data.auth.token);
        setIsProfileUpdate(res.data.auth);
      }
    } else {
      errorToast(
        (res && res.data && res.data.status && res.data.status.message) ||
          "Error Validating verification code, Please try again"
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: signUpValidation,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleSaveNext,
  });
  const { values, handleChange, handleBlur, errors, touched, isValid } = formik;

  return (
    <section className="d-flex justify-center">
      <div className="signup-modal">
        <div className="modal-dialog modal-dialog-centered">
          {isProfileUpdate ? (
            <>
              <MPHSignUpProfileUpdate
                validateToken ={validateToken} //passing the otp validate token as props
                token={isProfileUpdate}
                email={values.identifierValue}
                close={() => {
                  setIsOtp(false);
                  setIsProfileUpdate(false);
                }}
              />
            </>
          ) : (
            <>
              {!isOtp ? (
                <div className="modal-content form-modal-signup">
                  <div className="row">
                    <section className="mb-3">
                      <h2 className="form-header">Yes, I’d like to join</h2>
                    </section>
                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-2">
                      <label htmlFor="" className="form-label">
                        Email Address
                      </label>
                      <MPHInput
                        keyboardType="email"
                        onBlur={handleBlur("identifierValue")}
                        value={values.identifierValue}
                        onChangeText={(text: any) => {
                          handleChange("identifierValue")(text);
                        }}
                        isError={
                          touched.identifierValue! && !!errors.identifierValue
                        }
                        error={errors.identifierValue}
                        isModal={true}
                      />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-2 mb-3">
                      <label htmlFor="" className="form-label">
                        Password
                      </label>
                      <MPHInput
                        keyboardType="password"
                        value={values.password}
                        onBlur={handleBlur("password")}
                        onChangeText={(text: any) => {
                          handleChange("password")(text);
                        }}
                        isError={touched.password! && !!errors.password}
                        error={errors.password}
                        isModal={true}
                      />
                      <span className="password-helper mb-3">
                        The password must be at least 8 characters and contain
                        at least one uppercase character.
                      </span>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-2">
                      <label htmlFor="" className="form-label">
                        Confirm Password
                      </label>
                      <MPHInput
                        keyboardType="password"
                        value={values.confirmPassword}
                        onBlur={handleBlur("confirmPassword")}
                        onChangeText={(text: any) => {
                          handleChange("confirmPassword")(text);
                        }}
                        isError={
                          touched.confirmPassword! && !!errors.confirmPassword
                        }
                        error={errors.confirmPassword}
                        isModal={true}
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 mt-5">
                      <MPHButton
                        buttonText="Join For Free"
                        disabled={!isValid || isLoading}
                        isLoading={isLoading}
                        onbuttonClick={() => handleSaveNext()}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <MPHOtp
                  text={values.identifierValue}
                  isLoading={isLoading}
                  darkTheme={true}
                  isModal={false}
                  onClose={() => setIsOtp(false)}
                  onOtp={(otp: any) => otpValidateFunc(otp)}
                />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default MPHSignUp;
