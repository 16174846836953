/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect } from "react";
import "./MPHHeaderMain.scss";
import logo from "../../../assets/images/logo.png";
import menu from "../../../assets/images/menu.png";
import { useNavigate } from "react-router-dom";
import MPHButton from "../MPHButtons/MPHButton";
import useAuth from "src/context/AuthContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core";
import "bootstrap";
import { AUTH_ROUTES, INTERNAL_REDIRECTION_URLS } from "src/config/constants";
import { useStorage } from "src/utils/storage";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import { getEmail, isSessionValid } from "src/utils/session";

const MPHHeaderMain = ({ active }: any) => {
  const storage = useStorage();
  const { setTokens, setUserProfile, user, clearStorage } = useAuth();
  const { getCustomerByEmailLoggedIn } = useApiAxiosRequests();
  const navigate = useNavigate();

  const navigateToRoute = (route: any, redirect?: any) => {
    if (redirect) {
      window.open(redirect, "_blank");
    } else {
      navigate(route);
      window.scrollTo(0, 0);
    }
  };

  const checkValidSession = async () => {
    const token: any = await storage.get("app_tokens");
    const profile: any = await storage.get("user_profile");
    if (token && profile) {
      const authToken = JSON.parse(token);
      const userExisting = JSON.parse(profile);
      if (authToken && authToken.token) {
        if (!isSessionValid(authToken.token)) {
          storage.clear();
          setTokens({});
          setUserProfile(null);
        } else {
          const user: any = await getCustomerByEmailLoggedIn({
            email: userExisting.email,
          });
          const customer: any =
            user &&
            user.data &&
            user.data.customers &&
            user.data.customers.customer &&
            user.data.customers.customer.length &&
            user.data.customers.customer[0];
          if (!customer || !customer.email) {
            return;
          }
          setUserProfile({
            method: "auth",
            email: getEmail(storage),
            previousStep: "",
            token: authToken.token,
            role: "User",
            isProfileExist: true,
            links: AUTH_ROUTES,
            profileName: (customer && customer.firstname) || "",
            loyalty_points: (customer && customer.loyalty_points) || 0,
          });
        }
      }
    } else {
      storage.clear();
      setTokens({});
      setUserProfile(null);
    }
  };

  useEffect(() => {
    checkValidSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage]);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light header-main bg-white fixed-top">
        <span className="navbar-brand">
          <img
            src={logo}
            alt="Logo"
            onClick={() => navigateToRoute(null, INTERNAL_REDIRECTION_URLS.Web)}
            className="d-inline-block align-top logo"
          />
        </span>
        <button
          className="navbar-toggler"
          style={{
            border: "none",
            outline: "none",
            boxShadow: "none",
          }}
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="d-block">
            <img src={menu} alt="" />
            <span className="menu-c d-block">Menu</span>
          </span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-between"
          id="navbarNavDropdown"
        >
          <ul
            className={
              "navbar-nav width-100 " +
              (user && user.profileName
                ? "header-links-profile"
                : "header-links")
            }
          >
            {user &&
              user.links &&
              user.links.length &&
              user.links.map((link: any, i: any) => (
                <div key={"head" + i}>
                  <li key={link.route} className="nav-item">
                    <span
                      key={link.route}
                      className={
                        "nav-link " + (active === link.route ? "active" : "")
                      }
                      onClick={() => {
                        if (link.link) {
                          navigateToRoute(false, link.link);
                        } else {
                          navigateToRoute(link.route);
                        }
                      }}
                    >
                      {link.label}{" "}
                    </span>
                  </li>
                </div>
              ))}
            {!user ||
              (!user.email && (
                <li className="nav-item dropdown">
                  <span
                    key={"dropdown"}
                    className="nav-link dropdown-toggle primary-header-button mt-15 text-transform-none"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    STAY <em className="rewarded">Rewarded</em> 
                  </span>
                  <div
                    className="dropdown-menu custom-dropdown"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <span
                      className="dropdown-item"
                      onClick={() => navigateToRoute("/signup")}
                    >
                      Join
                    </span>
                    <span
                      className="dropdown-item"
                      onClick={() => navigateToRoute("/login")}
                    >
                      Login
                    </span>
                    <span
                      className="dropdown-item"
                      onClick={() => {
                        navigateToRoute(
                          false,
                          INTERNAL_REDIRECTION_URLS.MPH_STAY_REWARDED
                        );
                      }}
                    >
                      About Stay Rewarded
                    </span>
                  </div>
                </li>
              ))}
            {user && user.profileName && <></>}{" "}
          </ul>
        </div>
        {user && user.profileName && (
          <div className="user-greet-desktop nav-item dropdown mr-4">
            <p
              key={"dropdown"}
              role="button"
              id="dropdownProfileLink"
              data-bs-toggle="dropdown"
              className="mb-0 user-name"
              aria-expanded="false"
            >
              Hi, {user.profileName} |{" "}
              {(user.loyalty_points || 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              Points{" "}
            </p>
            <div
              className="dropdown-menu custom-dropdown"
              aria-labelledby="dropdownProfileLink"
            >
              <span
                className="dropdown-item"
                onClick={() => navigateToRoute("/status")}
              >
                My Status
              </span>
              <span
                className="dropdown-item"
                onClick={() => navigateToRoute("/rewards")}
              >
                My Rewards
              </span>
              <span
                className="dropdown-item"
                onClick={() => {
                  clearStorage();
                  setUserProfile(null);
                  navigate("/");
                }}
              >
                Logout
              </span>
            </div>
          </div>
        )}{" "}
        {user && user.profileName && (
          <div className="form-inline find-hotel">
            <MPHButton
              buttonText="Find Hotels"
              buttonType={"secondary-button"}
              onbuttonClick={() => navigateToRoute(null, INTERNAL_REDIRECTION_URLS.MPH_OUR_LOCATIONS)}
            />
          </div>
        )}
      </nav>
    </>
  );
};

export default MPHHeaderMain;
