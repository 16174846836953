import React from "react";
import "./MPHMyBenefitsCard.scss";
import waterBottle from "../../../assets/images/waterBottleIcon.png";

const MPHMyBenefitsCard = ({ imageContent }: any) => {
  return (
    <>
      <div className="my-benefits-card d-flex flex-column">
        <section className="benefits-img">
        <img
          className={imageContent.className}
          src={imageContent.image || waterBottle}
          alt=""
        />
        </section>
        <p className="para">{imageContent.title}</p>
      </div>
    </>
  );
};

export default MPHMyBenefitsCard;
