/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./MPHFooterPrimary.scss";
import footerLogo from "../../../assets/images/footerLogo.png";
import facebookIcon from "../../../assets/images/facebookIcon.png";
import instagramIcon from "../../../assets/images/instagramIcon.png";
import linkedInIcon from "../../../assets/images/linkedInIcon.png";
import twitterIcon from "../../../assets/images/twitterIcon.png";
import youTubeIcon from "../../../assets/images/youTubeIcon.png";

import facebookIconYellow from "../../../assets/images/facebook-yellow.svg";
import instagramIconYellow from "../../../assets/images/instagram-yellow.svg";
import linkedInIconYellow from "../../../assets/images/linked-yellow.svg";
import twitterIconYellow from "../../../assets/images/twitter-yellow.png";
import youTubeIconYellow from "../../../assets/images/youtube-yellow.svg";

// import btnIcon from "../../../assets/images/buttonTriangle.png";
import { useNavigate } from "react-router-dom";
import { EXTERNAL_URLS, INTERNAL_REDIRECTION_URLS } from "src/config/constants";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";

const MPHFooterPrimary = () => {
  const navigate = useNavigate();
  const { emailSubscription } = useApiAxiosRequests();
  const [facebook, setfacebook] = useState(false);
  const [twitter, settwitter] = useState(false);
  const [insta, setinsta] = useState(false);
  const [youtube, setyoutube] = useState(false);
  const [linkedin, setlinkedin] = useState(false);

  const navigateToPage = (route: any, redirect?: any) => {
    if (redirect) {
      window.open(redirect, "_blank");
    } else {
      navigate(route);
      window.scrollTo(0, 0);
    }
  };

  // const handleEmailSubscription = async (email: string) => {
  //   const res: any = await emailSubscription(email);
  // };

  return (
    <>
      <div className="footer-content primary-bg">
        <div className="container">
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
              <div className="logo text-center pb-4">
                <img src={footerLogo} alt="" />
              </div>
            </div>
            <div className="col-xl-10 col-lg-10 col-md-8 col-sm-12">
              <div className="content row text-left">
                <div className="content-card col-xl-3 col-lg-3 col-md-6 col-sm-12 pb-4">
                  <h2>Reservations</h2>
                  <span
                    onClick={() => {
                      navigateToPage(
                        false,
                        INTERNAL_REDIRECTION_URLS.MPH_VIEW_MODIFY_RESERVATIONS
                      );
                    }}
                  >
                    View/Modify Reservations
                  </span>
                  <span
                    onClick={() => {
                      navigateToPage(
                        false,
                        INTERNAL_REDIRECTION_URLS.MPH_GROUP_RESERVATION
                      );
                    }}
                  >
                    Group Reservations
                  </span>
                  <span
                    onClick={() => {
                      navigateToPage(
                        false,
                        INTERNAL_REDIRECTION_URLS.MPH_CORPORATE_TRAVEL
                      );
                    }}
                  >
                    Corporate Travel
                  </span>
                  <span
                    onClick={() => {
                      navigateToPage(
                        false,
                        INTERNAL_REDIRECTION_URLS.MPH_STAY_REWARDED
                      );
                    }}
                  >
                    Stay Rewarded
                  </span>
                </div>
                <div className="content-card col-xl-3 col-lg-3 col-md-6 col-sm-12 pb-4">
                  <h2>ABOUT</h2>
                  <span
                    onClick={() => {
                      navigateToPage(
                        false,
                        INTERNAL_REDIRECTION_URLS.MPH_ABOUT_MY_PLACE
                      );
                    }}
                  >
                    About My Place Hotels
                  </span>
                  <span
                    onClick={() => {
                      navigateToPage(
                        false,
                        INTERNAL_REDIRECTION_URLS.MPH_TRAVEL_UPDATE
                      );
                    }}
                  >
                    Travel Updates
                  </span>
                  <span
                    onClick={() => {
                      navigateToPage(
                        false,
                        INTERNAL_REDIRECTION_URLS.MPH_CAREERS
                      );
                    }}
                  >
                    Careers
                  </span>
                  <span
                    onClick={() => {
                      navigateToPage(
                        false,
                        INTERNAL_REDIRECTION_URLS.MPH_FRANCHISING
                      );
                    }}
                  >
                    Franchising
                  </span>
                  <span
                    onClick={() => {
                      navigateToPage(
                        false,
                        INTERNAL_REDIRECTION_URLS.MPH_TREND_BY_MY_PLACE
                      );
                    }}
                  >
                    Trend by My Place
                  </span>
                </div>
                <div className="content-card col-xl-3 col-lg-3 col-md-6 col-sm-12 pb-4">
                  <h2>EXPLORE</h2>
                  <span
                    onClick={() => {
                      navigateToPage(
                        false,
                        INTERNAL_REDIRECTION_URLS.MPH_MY_PLACE_BLOG
                      );
                    }}
                  >
                    My Place Blog
                  </span>
                  <span
                    onClick={() =>
                      navigateToPage(false, INTERNAL_REDIRECTION_URLS.MPH_OUR_LOCATIONS)
                    }
                  >
                    Our Locations
                  </span>
                </div>
                <div className="content-card col-xl-3 col-lg-3 col-md-6 col-sm-12 pb-4">
                  <h2>CONTACT</h2>
                  <span><a href="tel:+8552005685">855-200-5685</a></span>
                  <span
                    onClick={() =>
                      navigateToPage(false, INTERNAL_REDIRECTION_URLS.MPH_CONTACT_US)
                    }
                  >
                    Contact Us
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="subscription container mobile-device">
          <div className="row">
            {/* <div className="email-input row text-center col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <label
                htmlFor="subcription"
                className="mr-4 col-xl-4 col-lg-4 col-md-12 col-sm-12"
              >
                Signup for Email Updates
              </label>
              <span className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <input
                  type="email"
                  className="mr-2"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </span>
              <span className="col-xl-3 col-lg-3 col-md-12 col-sm-12 s-button">
                <button
                  className="primary-button"
                  onClick={() => {
                    handleEmailSubscription(email);
                  }}
                >
                  <span className="d-flex vertical-align-center">
                    <span>JOIN</span>
                    <img className="ml-2 mt-0" src={btnIcon} alt="" />
                  </span>
                </button>
              </span>
            </div> */}
            <div className="social-media col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <img
                src={!facebook ? facebookIcon : facebookIconYellow}
                alt=""
                onMouseOver={() => setfacebook(true)}
                onMouseOut={() => setfacebook(false)}
                onClick={() => {
                  navigateToPage(false, EXTERNAL_URLS.FaceBook);
                }}
              />
              <img
                src={!insta ? instagramIcon : instagramIconYellow}
                alt=""
                onMouseOver={() => setinsta(true)}
                onMouseOut={() => setinsta(false)}
                onClick={() => {
                  navigateToPage(false, EXTERNAL_URLS.Instagram);
                }}
              />
              <img
                src={twitter ? twitterIconYellow : twitterIcon}
                alt=""
                onMouseOver={() => settwitter(true)}
                onMouseOut={() => settwitter(false)}
                onClick={() => {
                  navigateToPage(false, EXTERNAL_URLS.Twitter);
                }}
              />
              <img
                src={linkedin ? linkedInIconYellow : linkedInIcon}
                alt=""
                onMouseOver={() => setlinkedin(true)}
                onMouseOut={() => setlinkedin(false)}
                onClick={() => {
                  navigateToPage(false, EXTERNAL_URLS.LinkedIn);
                }}
              />
              <img
                className="mr-0"
                src={youtube ? youTubeIconYellow : youTubeIcon}
                alt=""
                onMouseOver={() => setyoutube(true)}
                onMouseOut={() => setyoutube(false)}
                onClick={() => {
                  navigateToPage(false, EXTERNAL_URLS.YouTube);
                }}
              />
              {/* <img
                src={pinterestIcon}
                alt=""
                onClick={() => {
                  navigateToPage(false, EXTERNAL_URLS.Pinterest);
                }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MPHFooterPrimary;
