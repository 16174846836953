/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import MPHFooterPrimary from "src/components/actionPerformers/MPHFooterPrimary/MPHFooterPrimary";
import MPHFooterSecondary from "src/components/actionPerformers/MPHFooterSecondary/MPHFooterSecondary";
import MPHHeaderMain from "src/components/actionPerformers/MPHHeaderMain/MPHHeaderMain";
import MPHModalDetails from "src/components/actionPerformers/MPHModalDetails/MPHModalDetails";
import MPHPrimaryCard from "src/components/DataHolders/MPHPrimaryCard/MPHPrimaryCard";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import { errorToast } from "src/utils/toast";
import banner from "../../assets/banners/banner.png";
import "./RedeemPoints.scss";
import Swal from "sweetalert2";
import useAuth from "src/context/AuthContext";
import { useStorage } from "src/utils/storage";
import { useBlockUIContext } from "ns-react-block-ui";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const RedeemPoints = () => {
  const [redeemPointsData, setRedeemPoints] = useState([]);
  const [selected] = useState({});
  const { setBlockUI } = useBlockUIContext();
  const [userDetails, setUserDetails]: any = useState({});
  const { getCrmData, redeemPoints, getCustomerByEmailLoggedIn } =
    useApiAxiosRequests();
  const storage = useStorage();
  const { setUserProfile, user } = useAuth();
  const location = useLocation();

  const isRedeemablePoints = async (details: any) => {
    const profile: any = await storage.get("user_profile");
    if (profile) {
      const user = JSON.parse(profile);
      if (details.points > user.loyalty_points) {
        errorToast(
          "Current points are less than points requested for redemption"
        );
        return;
      }
    }
    Swal.fire({
      title: "Acknowledgement",
      text: `You are redeeming ${details.points
        .toString()
        .replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )} Stay Rewarded® points towards a ${details.header.replace(
        "EGift",
        "eGift"
      )}. Do you want to continue?`,
      icon: "success",
      cancelButtonText: "Cancel",
      confirmButtonText: "Ok",
      showCancelButton: true,
      customClass: {
        confirmButton: "secondary-button",
        cancelButton: "secondary-button",
      },
    }).then(
      async (res) => {
        if (res.isConfirmed) {
          setBlockUI({
            blocking: true,
          });
          const data: any = await redeemPoints({
            email: user.email,
            points: Number(details.points || 0) || 0,
            firstName: userDetails.firstname,
            lastName: userDetails.lastname,
          });
          if (
            data.data &&
            data.data.response &&
            data.data.response.status &&
            data.data.response.status.success !== "false"
          ) {
            document.getElementById("ModalDetailsEarn")?.click();
            removePoints(Number(details.points || 0) || 0);
          } else {
            errorToast(
              (data.data &&
                data.data.response &&
                data.data.response.status &&
                data.data.response.status.message) ||
                "Error redeem in points, Please try again"
            );
          }
          setBlockUI({ blocking: false });
        } else {
          setBlockUI({ blocking: false });
        }
        return;
      },
      () => {
        setBlockUI({ blocking: false });
      }
    );
  };

  const removePoints = async (points: any) => {
    const token: any = await storage.get("app_tokens");
    const profile: any = await storage.get("user_profile");
    if (token && profile) {
      const user = JSON.parse(profile);
      user.loyalty_points =
        Number(user.loyalty_points || 0) - Number(points || 0) || 0;
      setUserProfile(user);
    }
  };

  const getRedeemPointsFunc = async () => {
    // setBlockUI({ blocking: true });
    const res: any = await getCrmData("redeempoints");
    // setBlockUI({ blocking: false });
    if (res && res.data && res.data.data) {
      setRedeemPoints(res.data.data || []);
      return;
    }
  };

  const getloggedInUserFunc = async () => {
    const store: any = storage.get("user_profile");
    if (store && JSON.parse(store).email) {
      let payload: any = {
        email: JSON.parse(store).email,
      };
      const res: any = await getCustomerByEmailLoggedIn(payload);
      if (
        res &&
        res.data &&
        res.data.status &&
        res.data.status.success === "true"
      ) {
        let user =
          res.data.customers &&
          res.data.customers.customer &&
          res.data.customers.customer.length &&
          res.data.customers.customer[0];
        if (user) {
          setUserDetails({
            mobile: user.mobile,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            extended_fields: user.extended_fields,
            custom_fields: user.custom_fields,
            subscriptions: user.subscriptions,
          });
        }
      } else {
        setUserDetails({});
      }
    }
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    getRedeemPointsFunc();
    getloggedInUserFunc();
  }, []);

  return (
    <>
      <Helmet>
        <title>Redeem Your Points - Stay Rewarded</title>
        <meta name="description" content="Redeem your Stay Rewarded points for rewards and free nights. Make the most of your membership perks at My Place Hotels." />
      </Helmet>
      <MPHHeaderMain active="/redeem" />
      <div className="margin-top mt-web">
        {/* <MPHBanner
          url={banner}
          header={"Where will you go next?"}
          helperText={""}
        /> */}
        <section className="container mobile-device">
          <section className="d-flex justify-between">
            <div className="label-cards mb-3">
              <h2>Redeem Points</h2>
            </div>
          </section>
          <div className="row min-200">
            {redeemPointsData.map((card: any, index: any) => (
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-5"
                key={card.image + index}
              >
                <MPHPrimaryCard
                  key={index}
                  url={card.image || banner}
                  header={
                    card.title +
                    " (" +
                    card.points.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    " Points)"
                  }
                  height={"small"}
                  helperText={card.description}
                  buttonType={"secondary-button"}
                  buttonText={"Redeem"}
                  onbuttonClick={() => {
                    isRedeemablePoints({
                      header: card.title,
                      description: card.description,
                      points: card.points,
                    });
                  }}
                />
              </div>
            ))}{" "}
          </div>
        </section>
        <MPHFooterPrimary />
        <MPHFooterSecondary />
      </div>
      <MPHModalDetails
        details={selected}
        onClosed={() => getRedeemPointsFunc()}
      />
      <button
        hidden
        data-bs-toggle="modal"
        id="ModalDetailsEarn"
        data-bs-target="#ModalDetails"
      ></button>
    </>
  );
};

export default RedeemPoints;
