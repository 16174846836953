/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./MPHMyBenefitsComponent.scss";
import MPHMyBenefitsCard from "../MPHMyBenefitsCard/MPHMyBenefitsCard";
import { useApiAxiosRequests } from "src/utils/ApiService/MPHApis";
import { useBlockUIContext } from "ns-react-block-ui";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const MPHMyBenefitsComponent = () => {
  const [benfits, setBenfits] = useState([]);
  const { setBlockUI } = useBlockUIContext();
  const { getCrmData } = useApiAxiosRequests();
  const location = useLocation();

  const getBenfitsFunc = async () => {
    // setBlockUI({ blocking: true });
    const res: any = await getCrmData("mybenefits");
    // setBlockUI({ blocking: false });
    if (res && res.data && res.data.data) {
      setBenfits(res.data.data || []);
      return;
    }
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    getBenfitsFunc();
  }, []);

  return (
    <>
      <div className="my-benefits">
        <div className="my-benefits-header">
          <p className="m-0">Benefits</p>
        </div>
        <div className="my-benefits-contents d-flex flex-wrap justify-content-center align-items-center">
          {benfits.map((image: any, i: any) => {
            return <MPHMyBenefitsCard key={"key" + i} imageContent={image} />;
          })}{" "}
        </div>
        <div className="my-benefits-condition">
          <p className="m-0">*Subject to availability.</p>
        </div>
      </div>
    </>
  );
};

export default MPHMyBenefitsComponent;
